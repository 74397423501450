import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Columns, Form, Level, Pagination, Heading, Table, Notification } from 'react-bulma-components';
import { CountriesSelect } from '../../../components/elements/Fields/CountriesSelect';
import { FamCodesSelect } from '../../../components/elements/Fields/FamCodesSelect';
import { SegmentSelect } from '../../../components/elements/Fields/SegmentSelect';
import { MachineTypeSelect } from '../../../components/elements/Fields/MachineTypeSelect';
import { RdtSelect } from '../../../components/elements/Fields/RdtSelect';
import queryString from 'query-string';
import { saveAs } from 'file-saver';
import { Error } from '../../elements/Error'; 
import { Icon } from '../../elements/Icon';
import { mdiPlus, mdiExport, mdiMagnify, mdiDeleteVariant, mdiDeleteAlert, mdiDeleteCircle, mdiDeleteOutline } from '@mdi/js';
import { GET_MACHINES, DELETE_MACHINES } from '../../../database/foreignmachines';
import AzureB2CAuthenticator from '../../../application/authentication/azure-b2c-authenticator'

export const ForeignMachinesIndex = (props: any) => {

    const query = queryString.parse(window.location.search);

    // Uses location and history for redirecting with correct data
    const history = useHistory();
    const location = useLocation();

    // Get location state (state is passed though history.push)
    const state = location.state as any;

    // Redirect path
    const redirectTo = "/";

    // Default filters
    const initialFilter = {
        includeInactiveMachines: false,
    }

    // Sets filter
    const [filter, setFilter] = useState(initialFilter) as any;

    // Sets page size
    const [pageSize] = useState(20);
    const [pageIndex, setPageIndex] = useState(query.page ? Number(query.page) : 1);

    // Gets data and sets related states
    const { loading: queryLoading, error, data } = useQuery(GET_MACHINES, {
        variables: { pageSize, pageIndex, filter },
        fetchPolicy: 'network-only'
    });

    // Handle checkboxes
    const [deletionLoading, setDeleteLoading] = useState(false);
    const [itemsDeleted, setItemsDeleted] = useState("");
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]) as any;
    const [list, setList] = useState([]) as any;

    useEffect(() => {
        setList(data);
    }, [list]);
    
    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(data.foreignMachines.items.map((item: any) => item.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    
    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
          setIsCheck(isCheck.filter((item: any) => item !== id));
        }
    };

    const [deleteForeignMachines] = useMutation(DELETE_MACHINES, {
        refetchQueries: [
            {query: GET_MACHINES, 
                variables: { pageSize, pageIndex, filter }
            },
            'GetForeignMachines'
        ],
        onCompleted: (data) => handleDeleteSuccess(data),
        onError: (error) => {
            console.log(error);
        },
    });

    const handleDeletion = () => {

        // Show confirm dialog
        if (window.confirm("Do you really want to delete these items?") == true) {
            setDeleteLoading(true);

            // All guid are stored in inChecked variable
            deleteForeignMachines({ variables: { ids: isCheck } })
            
        } else {
            setDeleteLoading(false);
        }

    }

    /*
     * Takes care of the what happens after data has been deleted
     */
    const handleDeleteSuccess = (data: any) => {

        setIsCheck([]);
        setDeleteLoading(false);
        setItemsDeleted(data.deleteForeignMachines);

        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { deleted: true },
            search: location.search,
        });
    };

    // Export loading
    const [exportLoading, setExportLoading] = useState(false);

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleFilterInputChange = (e: any) => {
        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // Get the current data set
        const input = { ...filter }

        // Sets data key with a es6 computed property name
        input[name] = value;

        if (value === '' || value === undefined || value === null){
            delete input[name];
        }

        // Sets state
        setFilter(input);
        setPageIndex(1);

        // Send query params
        history.replace('?page=' + 1);
    }

    /*
    * Takes care of the submitted data and saves/updates
    */
    const handleSubmit = (event: any) => {
        event.preventDefault();
        //console.log(filter);
    }

    /*
    * Takes care of export
    */
    const handleExport = (event: any) => {
        event.preventDefault()

        setExportLoading(true);

        fetch(process.env.REACT_APP_EXPORT_URL  + '?' + queryString.stringify(filter), {
            headers: {
                'Content-Type': 'text/csv',
                'Authorization': `Bearer ${AzureB2CAuthenticator.getInstance().getIdentity()?.idToken}`,
                'Access-Control-Allow-Origin': '*',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY as string
            },
        })
        .then((response: any) => response.blob())
        .then((blob: any) => saveAs(blob, 'export.csv'))
        .finally(() => setExportLoading(false));
    }

    // Handles pagination clicks 
    const onPaginationPageChange = (page: number) => {
        // Sets the new page index, used by pagination
        setPageIndex(page);

        // Send query params
        history.replace('?page=' + page);
    };

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    const tableHeaders = (
        <tr>
            <th>
                <input 
                    id="check-all" 
                    className="is-checkradio is-small" 
                    type="checkbox" 
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                />
                <label htmlFor="check-all" className="pl-0"></label>
            </th>
            <th></th>
            <th>Serial</th>
            <th>CC FAM Code</th>
            <th>Brand</th>
            <th>Machine Type</th>
            <th>Model</th>
            <th>Worksite</th>
            <th>Operational status</th>
            <th>Local Serial Number</th>
            <th>Local Machine Name</th>
        </tr>
    );

    // Renders the page
    return (
        <div>

            {/* Filter */}
            <div className="box is-yellow is-marginless">
                <form onSubmit={handleSubmit}>
                    <Columns className="is-vcenter">
                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Serial number" name="serialNumber" value={filter.serialNumber} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Model" name="model" value={filter.model} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Field>
                                <CountriesSelect onChange={handleFilterInputChange} />
                            </Form.Field>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Field>
                                <FamCodesSelect onChange={handleFilterInputChange} />
                            </Form.Field>
                        </Columns.Column>

                        <Columns.Column className="is-2">					
                            <Form.Control>
                                <Form.Input placeholder="Customer number" name="customerNumber" value={filter.customerNumber} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Customer name" name="customerName" value={filter.customerName} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Brand" name="brand" value={filter.brand} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <MachineTypeSelect onChange={handleFilterInputChange} />
                                {/* <Form.Input placeholder="Machine Type" name="machineType" value={filter.machineType} onChange={handleFilterInputChange} /> */}
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                {/* <Form.Input placeholder="RDT Product Line" name="rdtProductLine" value={filter.rdtProductLine} onChange={handleFilterInputChange} /> */}
                                {/* <Form.Select
                                    className="is-fullwidth"
                                    id="rdtProductLine"
                                    name="rdtProductLine"
                                    value={filter.rdtProductLine}
                                    onChange={handleFilterInputChange}
                                >
                                    <option value="">RDT Product Line</option>
                                    <option value="ROT">ROT</option>
                                    <option value="EXE">EXE</option>
                                    <option value="THE">THE</option> 
                                    <option value="DTH">DTH</option>
                                </Form.Select> */}
                                <RdtSelect onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <SegmentSelect onChange={handleFilterInputChange} />
                                {/* <Form.Input placeholder="Segment" name="segment" value={filter.segment } onChange={handleFilterInputChange} /> */}
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Work Site" name="worksite" value={filter.worksite} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-2">
                            <Form.Control>
                                <Form.Input placeholder="Brand of consumables" name="rdtBrandOfConsumable" value={filter.rdtBrandOfConsumable} onChange={handleFilterInputChange} />
                            </Form.Control>
                        </Columns.Column>

                        <Columns.Column className="is-narrow">
                            <Form.Control>
                                <input
                                    className="is-checkradio has-background-color is-white"
                                    id="includeInactiveMachines"
                                    type="checkbox"
                                    name="includeInactiveMachines"
                                    value={filter.includeInactiveMachines}
                                    onChange={handleFilterInputChange} />
                                <label htmlFor="includeInactiveMachines">Include inactive</label>
                            </Form.Control>
                        </Columns.Column>

                    </Columns>
                </form>
            </div>

            {!queryLoading ? (

                <div className="box">
                    {/* Level */}
                    <Level renderAs="nav">
                        <Level.Side align="left">
                            <Level.Item>
                                <Heading size={3} subtitle>
                                    Found <strong>{data.foreignMachines.total}</strong> machines
                                    </Heading>
                            </Level.Item>
                        </Level.Side>

                        {queryLoading ? (
                            <Level.Side align="right">
                                <Level.Item>
                                    <div className="button is-text is-loading has-icon-dark" title="Loading"></div>
                                </Level.Item>
                            </Level.Side>
                        ) : (
                            <Level.Side align="right">
                                {isCheck.length != 0 ? (
                                    <Level.Item>
                                        <Button size="small" color="danger" onClick={handleDeletion} className={deletionLoading ? 'is-loading' : ''}>
                                            <Icon path={mdiDeleteOutline} color="#ffffff" />
                                            <strong>Delete machines</strong>
                                        </Button>
                                    </Level.Item>
                                ) : null}
                                <Level.Item>
                                        <Button size="small" color="light" onClick={handleExport} className={exportLoading ? 'is-loading' : ''}>
                                        <Icon path={mdiExport} color="#363636" />
                                        <strong>Export machines</strong>
                                    </Button>
                                </Level.Item>
                                <Level.Item>
                                    <Link to="/foreignmachine/create" className="button is-small is-light">
                                        <Icon path={mdiPlus} color="#363636" />
                                        <strong>Create machine</strong>
                                    </Link>
                                </Level.Item>
                            </Level.Side>
                        )}
                    </Level>

                    {state && state.created && (
                        <Notification color="success">
                            <strong>Success:</strong> Item has been created
                        </Notification>
                    )}

                    {(state && state.updated) && (
                        <Notification color="success">
                            <strong>Success:</strong> Item has been updated
                        </Notification>
                    )}

                    {state && state.deleted && itemsDeleted === "" && (
                        <Notification color="success">
                            <strong>Success:</strong> Item has been deleted
                        </Notification>
                    )}
                    
                    {state && state.deleted && itemsDeleted !== "" && (
                        <Notification color="success">
                            <strong>Success:</strong> {itemsDeleted}
                        </Notification>
                    )}

                    <div className="table-container">
                        <Table className="is-hoverable">
                            <thead>
                                {tableHeaders}
                            </thead>
                            <tfoot>
                                {tableHeaders}
                            </tfoot>
                            <tbody>
                                {
                                    // Checks is the filtered (if any) result isn't empty
                                    data.foreignMachines.items.length !== 0 ? (

                                        // Displays each row of the result
                                        data.foreignMachines.items.map((item: any, index: number) => {
                                            return (
                                                <tr key={index} >
                                                    <td>
                                                        <div className="control is-flex is-align-items-center mis-flex-grow-0">
                                                            <input 
                                                                id={item.id} 
                                                                type="checkbox" 
                                                                value={item.id} 
                                                                className="is-checkradio is-small"
                                                                onChange={handleClick}
                                                                checked={isCheck.includes(item.id)}
                                                            />
                                                            <label htmlFor={item.id} className="pl-0"></label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Link to={{ pathname: `/foreignmachine/${item.id}` }} className="button is-text is-small">
                                                            <Icon path={mdiMagnify} size="is-small" color="#363636" />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {item.serialNumber !== "" ? (
                                                            <Link to={{ pathname: `/foreignmachine/${item.id}` }}>{item.serialNumber}</Link>
                                                        ) : (
                                                            <span>N/A</span>
                                                        )}
                                                    </td>
                                                    <td>{item.country ? item.country.famCode : ''}</td>
                                                    <td>{item.brand ? item.brand.name : ''}</td>
                                                    <td>{item.machineType ? item.machineType.name : ''}</td>
                                                    <td>{item.model}</td>
                                                    <td>{item.worksite}</td>
                                                    <td>{item.operationalStatusCode ? "Active" : "Inactive"}</td>
                                                    <td>{item.localSerialNumber}</td>
                                                    <td>{item.localName}</td>
                                                </tr>
                                            )
                                        })

                                    ) : (
                                        // Returns a message if the result was empty
                                        <tr><td colSpan={9}>Nothing found..</td></tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>

                    {/* Displays pagination buttons */}
                    <Pagination className="is-dark is-small" current={pageIndex} total={Math.ceil(data.foreignMachines.total / pageSize)} delta={2} onChange={(page: number) => onPaginationPageChange(page)} />
                </div>
            )
            : (
                <div className="box">
                    <Button color="white" className="is-loading"></Button>
                </div>
            )}

            
        </div>
    );
}
