import gql from "graphql-tag";

export const GET_MACHINES = gql`
    query GetForeignMachines($pageSize: Int!, $pageIndex: Int!, $filter: ForeignMachineFilter) {
        foreignMachines(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter) {
            items {
                id
                brand {
                    id
                    name
                }
                country {
                    id
                    famCode
                }
                localName
                localSerialNumber
                machineType {
                    id
                    name
                }
                model
                operationalStatusCode
                serialNumber
                worksite
            }
            total
        }
    }
`;

export const GET_MACHINES_MODELS = gql`
    query GetForeignMachinesByFilter($pageSize: Int!, $pageIndex: Int!, $filter: ForeignMachineFilter) {
        foreignMachines(pageSize: $pageSize, pageIndex: $pageIndex, filter: $filter) {
            items {
                model
            }
        }
    }
`;

export const GET_MACHINE = gql`
    query GetForeignMachine($id: ID) {
        foreignMachine(id: $id) {
            id
            brand {
                name
                id
            }
            country {
                name
                famCode
                id
            }
            created
            createdBy
            customerName
            customerNumber
            dateOfVisiting
            dieselEngineHours
            dieselEngineHoursReadDate
            dieselEngineHoursUpdatedDate
            impactHours
            impactHoursReadDate
            impactHoursUpdatedDate
            localName
            localSerialNumber
            machineType {
                name
                id
            }
            manufacturingDate
            model
            operationalStatusCode
            rdtBrandOfConsumable
            rdtCostPerMeter
            rdtDrilledMetersPerYear
            rdtEnabled
            rdtProductLine {
                name
                id
            }
            commodity {
                name
                id
            }
            rdtRatio
            inputSource
            responsible
            rockCondition {
                name
                id
            }
            runningCostPerHour
            segment {
                name
                id
            }
            serialNumber
            stateOrRegion
            subequipmentSerialNumber1
            subequipmentSerialNumber2
            subequipmentSerialNumber3
            subequipmentSerialNumber4
            subequipmentComponentType1
            subequipmentComponentType2
            subequipmentComponentType3
            subequipmentComponentType4
            subequipmentBrand1
            subequipmentBrand2
            subequipmentBrand3
            subequipmentBrand4
            town
            updated
            updatedBy
            utilization
            worksite
        }
    }
`;

export const CREATE_MACHINE = gql`
    mutation CreateForeignMachine($foreignMachine: foreignMachineCreateInput!) {
        createForeignMachine(foreignMachine: $foreignMachine) {
            id
            brand {
                name
                id
            }
            country {
                id
            }
            created
            createdBy
            customerName
            customerNumber
            dateOfVisiting
            dieselEngineHours
            dieselEngineHoursReadDate
            dieselEngineHoursUpdatedDate
            impactHours
            impactHoursReadDate
            impactHoursUpdatedDate
            localName
            localSerialNumber
            machineType {
                id
            }
            manufacturingDate
            model
            operationalStatusCode
            rdtBrandOfConsumable
            rdtCostPerMeter
            rdtDrilledMetersPerYear
            rdtEnabled
            rdtProductLine {
                id
                name
            }
            commodity {
                name
                id
            }
            rdtRatio
            responsible
            rockCondition {
                id
            }
            runningCostPerHour
            segment {
                id
            }
            serialNumber
            stateOrRegion
            subequipmentSerialNumber1
            subequipmentSerialNumber2
            subequipmentSerialNumber3
            subequipmentSerialNumber4
            subequipmentComponentType1
            subequipmentComponentType2
            subequipmentComponentType3
            subequipmentComponentType4
            subequipmentBrand1
            subequipmentBrand2
            subequipmentBrand3
            subequipmentBrand4
            town
            updated
            updatedBy
            utilization
            worksite
        }
    }
`;

export const UPDATE_MACHINE = gql`
    mutation UpdateForeignMachine($foreignMachine: foreignMachineUpdateInput!, $updated: DateTime!, $forceUpdate: Boolean = null) {
        updateForeignMachine(foreignMachine: $foreignMachine, updated: $updated, forceUpdate: $forceUpdate) {
            id
            brand {
                name
                id
            }
            country {
                id
            }
            created
            createdBy
            customerName
            customerNumber
            dateOfVisiting
            dieselEngineHours
            dieselEngineHoursReadDate
            dieselEngineHoursUpdatedDate
            impactHours
            impactHoursReadDate
            impactHoursUpdatedDate
            localName
            localSerialNumber
            machineType {
                id
            }
            manufacturingDate
            model
            operationalStatusCode
            rdtBrandOfConsumable
            rdtCostPerMeter
            rdtDrilledMetersPerYear
            rdtEnabled
            rdtProductLine {
                id
                name
            }
            commodity {
                name
                id
            }
            rdtRatio
            responsible
            rockCondition {
                id
            }
            runningCostPerHour
            segment {
                id
            }
            serialNumber
            stateOrRegion
            subequipmentSerialNumber1
            subequipmentSerialNumber2
            subequipmentSerialNumber3
            subequipmentSerialNumber4
            subequipmentComponentType1
            subequipmentComponentType2
            subequipmentComponentType3
            subequipmentComponentType4
            subequipmentBrand1
            subequipmentBrand2
            subequipmentBrand3
            subequipmentBrand4
            town
            updated
            updatedBy
            utilization
            worksite
        }
    }
`;

export const DELETE_MACHINE = gql`
    mutation DeleteForeignMachine($id: ID!) {
        deleteForeignMachine(id: $id)
    }
`;

export const DELETE_MACHINES = gql`
    mutation DeleteForeignMachines($ids: [ID]) {
        deleteForeignMachines(ids: $ids)
    }
`;
