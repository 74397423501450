import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Form } from 'react-bulma-components';
import { Error } from '../../elements/Error';
import { GET_SEGMENTS } from '../../../database/segments';
//import { mdiOfficeBuildingMarker } from '@mdi/js';

import AzureB2CAuthenticator from '../../../application/authentication/azure-b2c-authenticator';

var authenticator = AzureB2CAuthenticator.getInstance();

export const SegmentSelect = (props: any) => {

    const identity = authenticator.getIdentity();

    const [currentValue, setCurrentValue] = useState();

    // Sets page size
    const [pageSize] = useState(-1);
    const [pageIndex] = useState(-1);

    // Gets data and sets related states
    const { loading, error, data } = useQuery(GET_SEGMENTS, {
        variables: { pageSize, pageIndex },
    });

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {

        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // Sets state
        setCurrentValue(value);

        // Send input up state
        props.onChange(e);
    }

    // Filters and return a unique set of data
    const getUnique = (array: Array<string>, key: string) => {

        // Creates a new array from a map
        const arrayUniqueByKey = [...Array.from(new Map(array.map((item: any) =>
            [item[key], item])).values())];
        
        // Reuturn a new filtered array
        return arrayUniqueByKey;
    }

    // Sorts set of data by famCode
    const sortByName = (a: any, b: any) => {

        // Sanitize and declare
        let x = a.famCode.toLowerCase();
        let y = b.famCode.toLowerCase();

        // Sort logic
        return x < y ? -1 : x > y ? 1 : 0;
    }

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <Form.Control>
            <Form.Select name="famCode" value={currentValue} onChange={handleInputChange} className="is-fullwidth">
                <option value="">Segment</option>
                { 
                    !loading && data.segments.items.length !== 0 ? (
                        data.segments.items.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{item.name}</option>
                            );
                        })
                    ) : ''
                }
            </Form.Select>
        </Form.Control>
    );
}

export interface SegmentSelectProps {

}
