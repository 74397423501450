import React, { Component } from "react";
import { Columns, Button } from "react-bulma-components";

import AzureB2CAuthenticator from "../application/authentication/azure-b2c-authenticator";
import { IdleTimeout } from "./IdleTimeout";

var authenticator = AzureB2CAuthenticator.getInstance();

interface AuthorizedAreaProps {
    autoSignin: boolean;
}
interface AuthorizedAreaState {
    isSignedIn: boolean;
    isSigningIn: boolean;
}

export default class AuthorizedArea extends Component<AuthorizedAreaProps, AuthorizedAreaState> {
    constructor(props: AuthorizedAreaProps) {
        super(props);
        this.state = {
            isSignedIn: false,
            isSigningIn: false,
        };
    }

    async componentDidMount(): Promise<void> {
        if (!this.state.isSignedIn && this.props.autoSignin) {
            await this.beginSigningIn();
        }

        if (authenticator.isAuthenticated() && authenticator.hasFamAccess()) {
            this.setState({ isSignedIn: true });
        }
    }

    async beginSigningIn(): Promise<void> {
        this.setState({ isSigningIn: true });
        await authenticator.login();
        this.setState({ isSignedIn: authenticator.isAuthenticated() && authenticator.hasFamAccess(), isSigningIn: false });
    }

    render() {
        if (this.state.isSignedIn) {
            return (
                <>
                    <IdleTimeout />
                    {this.props.children}
                </>
            );
        }

        return authenticator.isAuthenticated() && !authenticator.hasFamAccess() ? (
            <></>
        ) : (
            <div className="box is-yellow is-marginless">
                <Columns className="is-vcenter">
                    {this.state.isSigningIn ? (
                        <Button color="primary" className="is-loading">
                            <strong>Sign in</strong>
                        </Button>
                    ) : (
                        <>
                            {!this.props.autoSignin ? (
                                <Button color="primary" onClick={() => this.beginSigningIn()}>
                                    <strong>Sign in</strong>
                                </Button>
                            ) : (
                                <>Beginning auto SSO (A popup window should appear, if not make sure you have enabled popups for this page in your browser!)</>
                            )}
                        </>
                    )}
                </Columns>
            </div>
        );
    }
}
