import React from 'react';
import { Notification } from 'react-bulma-components';
import { Icon } from './Icon';
import { mdiLoading } from '@mdi/js';

export const Loading = (props: any) => {
    return (
        <Notification>
            <span className="loading">
                <Icon path={mdiLoading} spin color="#757763" />
                <strong>Loading ...</strong>
            </span>
        </Notification>
    );
}