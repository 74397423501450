export default class MolUserService {
  
  constructor(private molServiceEndpoint: string) {

  }

  public async getIdentity(token: string): Promise<MolIdentity> {
    console.log(this.molServiceEndpoint);
    const response = await fetch(this.molServiceEndpoint+"/me", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
        "Access-Control-Allow-Origin": "*"
      },
    });

    return await response.json() as MolIdentity; 
  }
}

class MolIdentity {
  isAdmin: boolean;
  accessibleFamCodes: Array<string>

  constructor(isAdmin: boolean, accessibleFamCodes: Array<string>) {
    this.isAdmin = isAdmin;
    this.accessibleFamCodes = accessibleFamCodes;
  }
}