import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Columns, Form, Level, Button, Heading, Notification } from 'react-bulma-components';
import { Error } from '../../../elements/Error';
import { Loading } from '../../../elements/Loading';
import { AdminSidebar } from './../shared/AdminSidebar';
import { Icon } from '../../../elements/Icon';
import { mdiArrowLeft, mdiContentSaveOutline, mdiTrashCanOutline } from '@mdi/js';
import { GET_RDT, UPDATE_RDT, DELETE_RDT } from '../../../../database/rdt';

export const AdminRdtEdit = (props: any) => {

    // Gets the id (guid) from params
    const { id } = useParams();

    // Uses location and history for redirecting with correct data
    const history = useHistory();
    const location = useLocation();

    // Redirect path
    const redirectTo = '/admin/rdts';

    // Defines all queries sets related states
    const { loading: queryLoading, error: queryError, data, client } = useQuery(GET_RDT, {
        variables: { id: id }
    });

    const [updateRDTProductLine, { loading: mutationUpdateLoading, error: mutationUpdateError }] = useMutation(UPDATE_RDT, {
        onCompleted: (data) => handleUpdateSuccess()
    });

    const [deleteRDTProductLine, { loading: mutationDeleteLoading, error: mutationDeleteError }] = useMutation(DELETE_RDT, {
        onCompleted: (data) => handleDeleteSuccess()
    });

    // Displays a loading screen while result is beeing fetched
    if (queryLoading) return <Loading />;

    // Checks for error in the fetched result
    if (queryError) return <Error message={queryError.message} />;

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {

        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // Get the current data set
        const input = { ...data.rdtProductLine }

        // Sets data key with a es6 computed property name
        input[name] = value;

        // Sets state
        client.writeQuery({
            query: GET_RDT,
            data: { rdtProductLine: input }
        });

    }

    /*
    * Takes care of the submitted data and saves/updates
    */
    const handleSubmit = (event: any) => {

        event.preventDefault();

        // Define data set
        const rdtProductLine = {
            id: data.rdtProductLine.id,
            name: data.rdtProductLine.name
        }

        // Defines current date
        const updated = new Date(Date.now()).toISOString();

        // Saves data and calls handleUpdateSuccess()
        updateRDTProductLine({ variables: { rdtProductLine: rdtProductLine, updated: updated } });

    }

    /*
    * Takes care of the submitted data and updates
    */
    const handleDelete = (event: any) => {

        event.preventDefault();

        // Delates data and calls handleDeleteSuccess()
        deleteRDTProductLine({ variables: { id: id } });
    }

    /*
    * Takes care of the what happens after data has been updated
    */
    const handleUpdateSuccess = () => {
        
        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { updated: true },
            search: location.search,
        });
    }

    /*
    * Takes care of the what happens after data has been deleted
    */
    const handleDeleteSuccess = () => {

        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { deleted: true },
            search: location.search,
        });
    }

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    {/* Level */}
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Button onClick={history.goBack} color="primary" size="small">
                                        <Icon path={mdiArrowLeft} color="#fff" />
                                        <strong>Back</strong>
                                    </Button>
                                </Level.Item>
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">{data.rdtProductLine.name}</Heading>
                                </Level.Item>
                            </Level.Side>
                        </Level >
                    </div>

                    {/* Page content */}
                    <div className="box">
                        
                        {(mutationUpdateError || mutationDeleteError) && (
                            <Notification color="danger">
                                <strong>Error:</strong> Please try again
                            </Notification>
                        )}

                        {(mutationDeleteLoading) && (
                            <Notification color="info">
                                <strong>Loading:</strong> ...
                            </Notification>
                        )}
                        
                        {/* Form */}
                        <form onSubmit={handleSubmit}>

                            <Columns>
                                <Columns.Column size={4}>
                                    <Form.Control>
                                        <Form.Label htmlFor="name">Name</Form.Label>
                                        <Form.Input id="name" name="name" value={data.rdtProductLine.name} onChange={handleInputChange} autoComplete="off" />
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            {/* Form save/delete button */}
                            <Form.Field>
                                <Form.Control className="is-clearfix">
                                    {mutationUpdateLoading ? (
                                        <Button className="is-pulled-left is-loading" color="primary" size="small" disabled="true">
                                            <span>Loading</span>
                                        </Button>
                                    ):(
                                        <Button type="submit" className="is-pulled-left" color="primary" size="small">
                                            <Icon path={mdiContentSaveOutline} color="#fff" />
                                            <span>Save</span>
                                        </Button>
                                    )}

                                    <Button className="is-text is-pulled-right" size="small" onClick={handleDelete}>
                                        <Icon path={mdiTrashCanOutline} color="#363636" size="small" />
                                        <span>Delete</span>
                                    </Button>
                                </Form.Control>

                            </Form.Field>

                        </form>

                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
}
