import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Columns, Level, Pagination, Heading, Table, Notification, Form, Button } from "react-bulma-components";
import queryString from "query-string";
import { DateTime } from "luxon";
import { dateFormat } from "../../../../config/app";
import { Error } from "../../../elements/Error";
import { AdminSidebar } from "./../shared/AdminSidebar";
import { Icon } from "../../../elements/Icon";
import { mdiPencil, mdiPlus, mdiExport, mdiClose } from "@mdi/js";
import { GET_MACHINE_TYPES } from "../../../../database/machinetypes";
import AzureB2CAuthenticator from "../../../../application/authentication/azure-b2c-authenticator";
import { saveAs } from "file-saver";

export const AdminMachineTypeIndex = (props: any) => {
    // Uses location and history for redirecting with correct data
    const history = useHistory();
    const location = useLocation();

    // Get location state (state is passed though history.push)
    const state = location.state as any;

    // Sets query params
    const [name] = useState<string | null>(null);
    const [pageSize] = useState(15);
    const [pageIndex, setPageIndex] = useState(location.search ? Number(queryString.parse(location.search).page) : 1);
    const [haveSearched, setHaveSearched] = useState(false);

    // Export loading
    const [exportLoading, setExportLoading] = useState(false);

    // Search query
    const [searchQuery, setSearchQuery] = useState("");

    // Gets data and sets related states
    const {
        loading: queryLoading,
        error,
        data,
        refetch,
    } = useQuery(GET_MACHINE_TYPES, {
        variables: { name, pageSize, pageIndex },
        fetchPolicy: "network-only",
    });

    // Handles pagination clicks
    const onPaginationPageChange = (page: number) => {
        // Sets the new page index, used by pagination
        setPageIndex(page);

        // Send query params
        history.push("?page=" + page);
    };

    // Set value on Input field when typing (Controlled input)
    const handleSearchQuery = (e: any) => {
        const val = e.target.value;
        setSearchQuery(val);
    };

    // Refetch data when clicking Search
    const handleSearch = () => {
        setHaveSearched(true);
        refetch({ name: searchQuery, pageSize, pageIndex });
        if (searchQuery.length === 0) {
            setHaveSearched(false);
        }
    };

    // Search on pressing enter
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSearch();
        } else {
            return;
        }
    };

    // Clear search
    const clearSearch = () => {
        setHaveSearched(false);
        setSearchQuery("");
        refetch({ name: null, pageSize, pageIndex });
    };

    const handleExport = (event: any) => {
        event.preventDefault();

        setExportLoading(true);

        fetch(process.env.REACT_APP_MOL_SERVICE_URL + "/machinetypes/export" + (searchQuery ? `?name=${searchQuery}` : ''), {
            headers: {
                "Content-Type": "text/csv",
                Authorization: `Bearer ${AzureB2CAuthenticator.getInstance().getIdentity()?.idToken}`,
                "Access-Control-Allow-Origin": "*",
                "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_KEY as string,
            },
        })
            .then((response: any) => response.blob())
            .then((blob: any) => saveAs(blob, "machine-types-export.csv"))
            .finally(() => setExportLoading(false));
    };

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    <div className="box is-yellow is-marginless is-mobile">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">
                                        Machine types
                                    </Heading>
                                </Level.Item>
                            </Level.Side>

                            {queryLoading ? (
                                <Level.Side align="right">
                                    <Level.Item>
                                        <div className="button is-text is-loading has-icon-dark" title="Loading"></div>
                                    </Level.Item>
                                </Level.Side>
                            ) : (
                                <Heading size={5} subtitle>
                                    Found <strong>{data.machineTypes.total}</strong> machine types
                                </Heading>
                            )}
                        </Level>
                    </div>

                    <div>
                        {!queryLoading ? (
                            <div className="box">
                                {/* Level */}
                                <Level renderAs="nav">
                                    <Level.Side align="left">
                                        <Level.Item>
                                            <div>
                                                <Level>
                                                    <Level.Item>
                                                        <Form.Control>
                                                            <Form.Input
                                                                id="search"
                                                                name="search"
                                                                value={searchQuery}
                                                                onChange={handleSearchQuery}
                                                                placeholder="Search"
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </Form.Control>
                                                    </Level.Item>

                                                    {haveSearched && (
                                                        <Level.Item>
                                                            <Button color="light" onClick={clearSearch}>
                                                                Clear
                                                            </Button>
                                                        </Level.Item>
                                                    )}
                                                    <Level.Item>
                                                        <Button color="primary" onClick={handleSearch}>
                                                            Search
                                                        </Button>
                                                    </Level.Item>
                                                </Level>
                                            </div>
                                        </Level.Item>
                                    </Level.Side>

                                    <Level.Side align="right">
                                        <Level.Item>
                                            <Columns>
                                                <Columns.Column>
                                                    <Link to="/admin/machinetypes/create" className="button is-small is-light">
                                                        <Icon path={mdiPlus} color="#363636" />
                                                        <strong>Create a machine type</strong>
                                                    </Link>
                                                </Columns.Column>
                                                <Columns.Column>
                                                    <Button size="small" color="light" onClick={handleExport} className={exportLoading ? "is-loading" : ""}>
                                                        <Icon path={mdiExport} color="#363636" />
                                                        <strong>Export machine types</strong>
                                                    </Button>
                                                </Columns.Column>
                                            </Columns>
                                        </Level.Item>
                                    </Level.Side>
                                </Level>

                                {state && state.created && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been created
                                    </Notification>
                                )}

                                {state && state.updated && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been updated
                                    </Notification>
                                )}

                                {state && state.deleted && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been deleted
                                    </Notification>
                                )}

                                <div className="table-container">
                                    <Table className="is-hoverable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th className="is-narrow">Created at</th>
                                                <th className="is-narrow">Updated at</th>
                                                <th className="is-narrow has-text-right"></th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Name</th>
                                                <th className="is-narrow">Created at</th>
                                                <th className="is-narrow">Updated at</th>
                                                <th className="is-narrow has-text-right"></th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                // Checks is the filtered (if any) result isn't empty
                                                data.machineTypes.items.length !== 0 ? (
                                                    // Displays each row of the result
                                                    data.machineTypes.items.map((item: any, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td className="is-narrow">{DateTime.fromISO(item.created).toFormat(dateFormat)}</td>
                                                                <td className="is-narrow">{DateTime.fromISO(item.updated).toFormat(dateFormat)}</td>
                                                                <td className="is-narrow">
                                                                    <Link
                                                                        to={{ pathname: `/admin/machinetypes/machinetype/${item.id}`, search: location.search }}
                                                                        className="button is-text is-small"
                                                                    >
                                                                        <Icon path={mdiPencil} color="#363636" />
                                                                        <strong>Edit</strong>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    // Returns a message if the result was empty
                                                    <tr>
                                                        <td colSpan={4}>Nothing found..</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>

                                {/* Displays pagination buttons */}
                                <Pagination
                                    className="is-dark is-small"
                                    current={pageIndex}
                                    total={Math.ceil(data.machineTypes.total / pageSize)}
                                    delta={2}
                                    onChange={(page: number) => onPaginationPageChange(page)}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
};
