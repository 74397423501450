import gql from 'graphql-tag';

export const GET_ROCK_CONDITIONS = gql`
    query GetRockConditions ($pageSize: Int!, $pageIndex: Int!) {
        rockConditions (pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
            }
            total
        }
    }
`;

export const GET_ROCK_CONDITION = gql`
    query GetRockCondition ($id: ID!) {
        rockCondition (id: $id) {
            id
            name
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_ROCK_CONDITION = gql`
    mutation CreateRockCondition ($condition: rockConditionCreateInput!) {
        createRockCondition (rockCondition: $condition) {
            id
            name
            updated
            created
        }
    }
`;

export const UPDATE_ROCK_CONTITION = gql`
    mutation UpdateRockCondition ($rockCondition: rockConditionUpdateInput!, $updated: DateTime!) {
        updateRockCondition (rockCondition: $rockCondition, updated: $updated) {
            id
            name
            updated
            created
        }
    }
`;

export const DELETE_ROCK_CONTITION = gql`
    mutation DeleteRockCondition ($id: ID!){
        deleteRockCondition (id: $id)
    }
`;