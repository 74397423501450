import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Navbar } from 'react-bulma-components';
import { Icon } from '../elements/Icon';
import { mdiArrowLeft } from '@mdi/js';
import { navbar_title } from '../../config/app';

import { Identity } from '../../application/authentication/azure-b2c-authenticator';

const logo = require('../../resources/assets/images/logo.png');

export const Header = (props: HeaderProps) => {

  const [active, setActive] = useState(false);
  const toggleActive = () => setActive(!active);

  const identity = props.identity;
  const isSignedIn = props.isSignedIn;
  const hasFamAccess = props.hasFamAccess;
  const signoutFunc = props.signoutFunc;

  return (
      <Navbar active={active} onClick={toggleActive} className="is-primary">
          <Navbar.Item renderAs="div">
              <Button.Group>
                  <Button renderAs="a" href="#" color="primary" size="small">
                      <Icon path={mdiArrowLeft} color="#fff" />
                      <strong>Back to portal</strong>
                  </Button>
              </Button.Group>
          </Navbar.Item>
          <Navbar.Brand>
              <NavLink to="/" className="navbar-item" activeClassName="is-active" exact>
                  <img src={logo} alt="Logo" height="28" />
                  <strong>{navbar_title}</strong>
              </NavLink>
              <Navbar.Burger />
          </Navbar.Brand>
          <Navbar.Menu >
          {isSignedIn
          && <Navbar.Container position="end">
            {identity?.isAdmin && hasFamAccess && <NavLink to="/admin" className="navbar-item" activeClassName="is-active">
              Administration
            </NavLink>}
             
              <Navbar.Item dropdown hoverable className="navbar-item" renderAs="div">
              <Navbar.Link className="navbar-item">{isSignedIn ? identity?.displayName : "Login"}</Navbar.Link>
              {isSignedIn && 
                <Navbar.Dropdown>
                  <Navbar.Item onClick={() => { signoutFunc()}}>
                    Logout
                  </Navbar.Item>
                </Navbar.Dropdown>}
              </Navbar.Item>
            </Navbar.Container>
          }   
          </Navbar.Menu>
      </Navbar>
  );
}

export interface HeaderProps {
  identity: Identity | null;
  isSignedIn: boolean;
  hasFamAccess: boolean;
  signoutFunc: () => void;
}