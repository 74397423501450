import gql from 'graphql-tag';

export const GET_SEGMENTS = gql`
    query GetSegments ($pageSize: Int!, $pageIndex: Int!) {
        segments (pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
            }
            total
        }
    }
`;

export const GET_SEGMENT = gql`
    query GetSegment ($id: ID!) {
        segment (id: $id) {
            id
            name
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_SEGMENT = gql`
    mutation CreateSegment ($segment: segmentCreateInput!) {
        createSegment (segment: $segment) {
            id
            name
            updated
            created
        }
    }
`;

export const UPDATE_SEGMENT = gql`
    mutation UpdateSegment ($segment: segmentUpdateInput!, $updated: DateTime!) {
        updateSegment (segment: $segment, updated: $updated) {
            id
            name
            updated
            created
        }
    }
`;

export const DELETE_SEGMENT = gql`
    mutation DeleteSegment ($id: ID!){
        deleteSegment (id: $id)
    }
`;