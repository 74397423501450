import * as Msal from 'msal';
import MolUserService from './mol-user-service';

export enum StorageLocation {
    LocalStorage,
    SessionStorage
}

export interface TokenContainer {
    expires: Date,
    token: string
}

export interface AuthenticationConfig {
    tenant: string;
    policy: string;
    clientId: string;
    idScopes: Array<string>;
    accessScopes: Array<string>;
    redirectUri: string;
    acquireAccessToken?: boolean;
    storageLocation: StorageLocation;
    molService: MolUserService;
    onLoginSuccess?: (identity: Identity) => void,
    onAccessTokenRecieved?: (token: string) => void,
    onIdTokenRecieved?: (token: string) => void,
    onLoginFail?: () => void,
}

export default class AzureB2CAuthenticator {

  private static instance: AzureB2CAuthenticator;

  private msalObject: Msal.UserAgentApplication = {} as any;
  private hasBeenInitialized: boolean = false;
  private config: AuthenticationConfig = {} as any;

  private onAccessTokenRecieved: (token: string) => void = (token: string) => { };
  private onIdTokenRecieved: (token: string) => void = (token: string) => { };
  private onLoginSuccess: (identity: Identity) => void = (identity: Identity) => { };
  private onAuthFailCallback: () => void = () => { };

  private constructor() { }

  public static getInstance(): AzureB2CAuthenticator {
    if (!AzureB2CAuthenticator.instance)
      AzureB2CAuthenticator.instance = new AzureB2CAuthenticator();

    return AzureB2CAuthenticator.instance;
  }

  public initialize(config: AuthenticationConfig): void {
    this.config = config;

    this.msalObject = new Msal.UserAgentApplication({
      auth: {
        clientId: config.clientId,
        authority: `https://${config.tenant.split('.')[0]}.b2clogin.com/tfp/${config.tenant}/${config.policy}`,
        validateAuthority: false,
        redirectUri: config.redirectUri
      },
      cache: {
        cacheLocation: config.storageLocation === StorageLocation.LocalStorage
          ? "localStorage"
          : "sessionStorage",
      }
    });

    this.msalObject.handleRedirectCallback(this.handleAuthResponse.bind(this));

    if (this.config.onAccessTokenRecieved) {
      this.onAccessTokenRecieved = this.config.onAccessTokenRecieved;
    }
    if (this.config.onIdTokenRecieved) {
      this.onIdTokenRecieved = this.config.onIdTokenRecieved;
    }
    if (this.config.onLoginSuccess) {
      this.onLoginSuccess = this.config.onLoginSuccess;
    }

    this.hasBeenInitialized = true;
  }

  public async login(): Promise<void> {
    if (!this.hasBeenInitialized)
      throw new Error("Authenticator must have been initialized before logging in.");

    try {
      var response = await this.msalObject.loginPopup();
      await this.handleAuthResponse(undefined, response);

      var identity = this.getIdentity();
      if (identity != null) {
        this.onLoginSuccess(identity);
      }
    } catch (error) {
      console.error(error);
    }
  }

  public logout(): void {
    localStorage.removeItem(`fe.id_token`);
    localStorage.removeItem(`fe.access_token`);
    localStorage.removeItem(`fe.is_admin`);
    localStorage.removeItem(`fe.fam_codes`);
    this.msalObject.logout();
  }

  public getIdentity(): Identity | null {

    const accessToken = this.getCachedToken("access_token");
    const idToken = this.getCachedToken("id_token");

    if (this.msalObject.getAccount() && (accessToken != null || idToken != null))
      return new Identity(
        this.msalObject.getAccount().name,
        this.getCachedToken("access_token") ?? "",
        this.getCachedToken("id_token") ?? "",
        this.getCachedIsAdmin() ?? false,
        this.getCachedFamCodes()
      );

    return null;
  }

  public isAuthenticated(): boolean {
    return this.getIdentity() != null;
  }

  public hasFamAccess(): boolean {
    const famArray = this.getCachedFamCodes();
    return this.isAuthenticated() && famArray != null && famArray.length > 0 && famArray[0] != '';
  }

  protected async handleAuthResponse(authErr?: Msal.AuthError, response?: Msal.AuthResponse): Promise<void> {
    if (authErr)
      throw authErr;

    if (!response)
      throw new Error("Did not recieve a auth response.");

    if (response.tokenType === "id_token") {
      await this.idTokenFlow.bind(this)(response);
    }
    else if (response.tokenType === "access_token") {
      await this.accessTokenFlow.bind(this)(response);
    }
    else {
      throw new Error(`Unknown token type: ${response.tokenType}`);
    }

    await this.loadMolIdentity(this.getCachedToken('id_token') ?? "");
  }

  protected async loadMolIdentity(token: string): Promise<void> {
    const molIdentity = await this.config.molService.getIdentity(token);
    localStorage.setItem(`fe.is_admin`, molIdentity.isAdmin ? "true" : "false");
    localStorage.setItem(`fe.fam_codes`, molIdentity.accessibleFamCodes?.join() ?? "");
  }

  private async idTokenFlow(response: Msal.AuthResponse): Promise<void> {
    this.cacheToken('id_token', response.idToken.rawIdToken, new Date(+response.idToken.expiration * 1000));
    this.onIdTokenRecieved(response.idToken.rawIdToken);

    await this.acquireAccessToken();
  }

  private async accessTokenFlow(response: Msal.AuthResponse): Promise<void> {
    this.cacheToken('access_token', response.accessToken, response.expiresOn);
    
    this.onAccessTokenRecieved(response.accessToken);
  }

  private async acquireAccessToken(): Promise<void> {

    const scopes = this.config.accessScopes;

    if (this.isIE()) {
      await this.msalObject.acquireTokenPopup({ scopes: scopes });
      return;
    }

    let tokenResponse = await this.msalObject.acquireTokenSilent({ scopes: scopes }).catch(async (error) => {
      if (this.requiresInteraction(error.errorCode)) {
        return await this.msalObject.acquireTokenPopup({
          scopes: scopes
        });
      }
    });

    if (tokenResponse) {
      await this.accessTokenFlow(tokenResponse);
      return;
    }

    this.onAuthFailCallback();
  }

  private cacheToken(tokenType: TokenType, token: string, expires: Date): void {
    const tokenContainer = {
      expires: expires,
      token: token
    };
    
    localStorage.setItem(`fe.${tokenType}`, JSON.stringify(tokenContainer));
  }

  private getCachedFamCodes(): Array<string> {
    return localStorage.getItem(`fe.fam_codes`)?.split(',') ?? new Array<string>();
  }

  private getCachedIsAdmin(): boolean {
   return localStorage.getItem(`fe.is_admin`) === 'true';
  }

  private getCachedToken(tokenType: TokenType): string|null {
    const containerStr = localStorage.getItem(`fe.${tokenType}`);
    if (!containerStr)
      return null;

    const container = JSON.parse(containerStr) as TokenContainer;

    if (!container)
      return null;

    if (new Date() > new Date(container.expires)) {
      localStorage.removeItem(`fe.${tokenType}`);
      return null;
    }

    return container.token;
  }

  private isIE(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // for edge in private mode.
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
  }
  
  private requiresInteraction(errorMessage: string): boolean {
    if (!errorMessage || !errorMessage.length) {
      return false;
    }

    return (
      errorMessage.indexOf("consent_required") > -1 ||
      errorMessage.indexOf("interaction_required") > -1 ||
      errorMessage.indexOf("login_required") > -1
    );
  }
}

export class Identity {
  readonly displayName: string;
  readonly accessToken: string;
  readonly idToken: string;
  readonly isAdmin: boolean;
  readonly famCodeAccess: Array<string>;

  constructor(displayName: string, accessToken: string, idToken: string, isAdmin: boolean, famCodeAccess: Array<string>) {
    this.displayName = displayName;
    this.accessToken = accessToken;
    this.idToken = idToken;
    this.isAdmin = isAdmin;
    this.famCodeAccess = famCodeAccess;
  }
}

declare type TokenType = "id_token" | "access_token";