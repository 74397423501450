import React from "react";
import { Columns, Form, Heading } from "react-bulma-components";

export interface SubEquipmentProps {
    data: any;
    onChange: any;
}

export const SubEquipment = (props: SubEquipmentProps) => {
    const { data } = props;

    // Renders the page
    return (
        <div className="box is-light">
            <Heading subtitle size={6} renderAs="h2">
                Sub equipment
            </Heading>
            <Columns>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentSerialNumber1">Serial Number</Form.Label>
                        <Form.Input
                            id="subequipmentSerialNumber1"
                            name="subequipmentSerialNumber1"
                            type="text"
                            value={data.foreignMachine.subequipmentSerialNumber1 ? data.foreignMachine.subequipmentSerialNumber1 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentComponentType1">Component Type</Form.Label>
                        <Form.Input
                            id="subequipmentComponentType1"
                            name="subequipmentComponentType1"
                            type="text"
                            value={data.foreignMachine.subequipmentComponentType1 ? data.foreignMachine.subequipmentComponentType1 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentBrand1">Brand</Form.Label>
                        <Form.Input
                            id="subequipmentBrand1"
                            name="subequipmentBrand1"
                            type="text"
                            value={data.foreignMachine.subequipmentBrand1 ? data.foreignMachine.subequipmentBrand1 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentSerialNumber2">Serial Number</Form.Label>
                        <Form.Input
                            id="subequipmentSerialNumber2"
                            name="subequipmentSerialNumber2"
                            type="text"
                            value={data.foreignMachine.subequipmentSerialNumber2 ? data.foreignMachine.subequipmentSerialNumber2 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentComponentType2">Component Type</Form.Label>
                        <Form.Input
                            id="subequipmentComponentType2"
                            name="subequipmentComponentType2"
                            type="text"
                            value={data.foreignMachine.subequipmentComponentType2 ? data.foreignMachine.subequipmentComponentType2 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentBrand2">Brand</Form.Label>
                        <Form.Input
                            id="subequipmentBrand2"
                            name="subequipmentBrand2"
                            type="text"
                            value={data.foreignMachine.subequipmentBrand2 ? data.foreignMachine.subequipmentBrand2 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentSerialNumber3">Serial Number</Form.Label>
                        <Form.Input
                            id="subequipmentSerialNumber3"
                            name="subequipmentSerialNumber3"
                            type="text"
                            value={data.foreignMachine.subequipmentSerialNumber3 ? data.foreignMachine.subequipmentSerialNumber3 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentComponentType3">Component Type</Form.Label>
                        <Form.Input
                            id="subequipmentComponentType3"
                            name="subequipmentComponentType3"
                            type="text"
                            value={data.foreignMachine.subequipmentComponentType3 ? data.foreignMachine.subequipmentComponentType3 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentBrand3">Brand</Form.Label>
                        <Form.Input
                            id="subequipmentBrand3"
                            name="subequipmentBrand3"
                            type="text"
                            value={data.foreignMachine.subequipmentBrand3 ? data.foreignMachine.subequipmentBrand3 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>

            <Columns>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentSerialNumber4">Serial Number</Form.Label>
                        <Form.Input
                            id="subequipmentSerialNumber4"
                            name="subequipmentSerialNumber4"
                            type="text"
                            value={data.foreignMachine.subequipmentSerialNumber4 ? data.foreignMachine.subequipmentSerialNumber4 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentComponentType4">Component Type</Form.Label>
                        <Form.Input
                            id="subequipmentComponentType4"
                            name="subequipmentComponentType4"
                            type="text"
                            value={data.foreignMachine.subequipmentComponentType4 ? data.foreignMachine.subequipmentComponentType4 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column size={3}>
                    <Form.Control>
                        <Form.Label htmlFor="subequipmentBrand4">Brand</Form.Label>
                        <Form.Input
                            id="subequipmentBrand4"
                            name="subequipmentBrand4"
                            type="text"
                            value={data.foreignMachine.subequipmentBrand4 ? data.foreignMachine.subequipmentBrand4 : ""}
                            onChange={props.onChange}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>
        </div>
    );
};
