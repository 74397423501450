import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { Button } from "react-bulma-components";

export const IdleTimeout: React.FC = () => {
    let history = useHistory();
    // Set timeout values

    // 20 min timeout
    const timeout = 6000000;

    // 2 min modal asking still there
    const promptTimeout = 120000;

    // Modal open state
    const [open, setOpen] = useState(false);

    // Time before idle
    const [remaining, setRemaining] = useState(0);

    const onPrompt = () => {
        // onPrompt will be called after the timeout value is reached
        // In this case 30 minutes. Here you can open your prompt.
        // All events are disabled while the prompt is active.
        // If the user wishes to stay active, call the `reset()` method.
        // You can get the remaining prompt time with the `getRemainingTime()` method,
        setOpen(true);
        setRemaining(promptTimeout);
    };

    const onIdle = () => {
        // onIdle will be called after the promptTimeout is reached.
        // In this case 30 seconds. Here you can close your prompt and
        // perform what ever idle action you want such as log out your user.
        // Events will be rebound as long as `stopOnMount` is not set.
        history.push("/");
        setOpen(false);
        setRemaining(0);
    };

    const onActive = (event: Event | undefined) => {
        // onActive will only be called if `reset()` is called while `isPrompted()`
        // is true.  Here you will also want to close your modal and perform
        // any active actions.
        setOpen(false);
        setRemaining(0);
    };

    const { getRemainingTime, isPrompted, reset } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive,
    });

    const handleStillHere = () => {
        setOpen(false);
        reset();
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <div className={`modal ${open ? "is-active" : ""}`}>
            <div className="modal-background" />
            <div className="modal-card">
                <div className="box is-yellow" style={{ margin: 0 }}>
                    <p className="modal-card-title">Are you still there?</p>
                </div>
                <section className="modal-card-body">
                    <p>Returning you to Homepage in {remaining} seconds</p>
                </section>
                <footer className="modal-card-foot">
                    <Button onClick={handleStillHere} color="primary">
                        Im still here
                    </Button>
                </footer>
            </div>
        </div>
    );
};
