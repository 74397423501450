import React, { useState } from "react";
import { Button, Columns, Form, Heading, Level } from "react-bulma-components";
import { AdminSidebar } from "./../shared/AdminSidebar";
import { Icon } from "../../../elements/Icon";
import { mdiUpload, mdiExport } from "@mdi/js";
import { Error as ErrorMessage } from "../../../elements/Error";
import { Success } from "../../../elements/Success";
import { saveAs } from "file-saver";

import AzureB2CAuthenticator from "../../../../application/authentication/azure-b2c-authenticator";

export const AdminImport = (props: any) => {
    const fileInput = React.createRef() as any;
    const fileName = React.createRef() as any;

    // Sets filename to dom when selected
    const fileOnChangeHandler = () => {
        if (fileInput.current.files.length > 0) {
            fileName.current.textContent = fileInput.current.files[0].name;
        }
    };

    // Export states
    const [importLoading, setImportLoading] = useState(false);
    const [importError, setImportError] = useState(null) as any;
    const [importSuccess, setImportSuccess] = useState(false);
    const [importFailed, setImportFailed] = useState(false);
    const [importLog, setImportLog] = useState([]);
    //const [downloadInProcess, setDownloadInProcess] = useState(false);

    const downloadTemplate = (event: any) => {
        //setDownloadInProcess(true);

        fetch(process.env.REACT_APP_IMPORT_TEMPLATE_URL ?? "", {
            headers: {
                Authorization: `Bearer ${AzureB2CAuthenticator.getInstance().getIdentity()?.idToken}`,
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((response: any) => response.blob())
            .then((blob: any) => saveAs(blob, "foreignequipment_machineimport_template.csv"));
        //.finally(() => setDownloadInProcess(false));
    };

    // File upload handler
    const handleUploadFile = async (e: any) => {
        try {
            e.preventDefault();

            // Checks if there's a file selected
            if (!fileInput.current.files[0]) {
                return setImportError({ message: "No file selected" });
            }

            setImportLoading(true);
            setImportError(null);
            setImportLog([]);
            setImportSuccess(false);
            setImportFailed(false);

            // Creates Form data
            const data = new FormData();
            data.append("files", fileInput.current.files[0]);
            data.append("filename", fileInput.current.files[0].name);

            const response = await fetch(process.env.REACT_APP_IMPORT_URL ?? "", {
                method: "POST",
                body: data,
                headers: {
                    Authorization: `Bearer ${AzureB2CAuthenticator.getInstance().getIdentity()?.idToken}`,
                    "Access-Control-Allow-Origin": "*",
                },
            });

            const result = await response.json();

            setImportLog(result);

            // Something went wrong
            if (!response.ok) {
                setImportFailed(true);
                setImportLoading(false);
                setImportSuccess(false);

                throw new Error("File not imported");
            }

            // It all works, carry on
            setImportFailed(false);
            setImportSuccess(true);
            setImportLoading(false);
        } catch (error) {
            setImportError({ message: "File not imported" });
        }
    };

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Heading size={3} subtitle>
                                        Import
                                    </Heading>
                                </Level.Item>
                            </Level.Side>
                            <Level.Side align="right">
                                <Level.Item>
                                    <Button target="_blank" onClick={downloadTemplate} download className="button">
                                        <Icon path={mdiExport} color="#363636" />
                                        <strong>Download template</strong>
                                    </Button>
                                </Level.Item>
                            </Level.Side>
                        </Level>
                    </div>

                    {/* Page content */}
                    <div className="box">
                        {importSuccess ? (
                            <>
                                <Success message="File imported" />
                                <div className="log box">
                                    {importLog.map((logRow, index) => (
                                        <div key={index}>{logRow}</div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                        {importFailed && (
                            <>
                                {importError && <ErrorMessage message={importError.message} />}

                                <div className="log box">
                                    {importLog.map((logRow, index) => (
                                        <p key={index}>{logRow}</p>
                                    ))}
                                </div>
                            </>
                        )}

                        <form onSubmit={handleUploadFile}>
                            <Form.Field>
                                <div className="file has-name">
                                    <label className="file-label">
                                        <input className="file-input" type="file" onChange={fileOnChangeHandler} ref={fileInput} />
                                        <span className="file-cta">
                                            <Icon className="file-icon" path={mdiUpload} color="#363636" size="small" />
                                            <span className="file-label">Choose a file ...</span>
                                        </span>
                                        <span className="file-name" ref={fileName}>
                                            No file selected
                                        </span>
                                    </label>
                                </div>
                            </Form.Field>

                            <Form.Field>
                                <Button type="submit" color="primary" className={importLoading ? "is-loading" : ""}>
                                    Upload
                                </Button>
                            </Form.Field>
                        </form>
                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
};
