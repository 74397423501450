import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Form } from "react-bulma-components";
import { Error } from "../Error";

import AzureB2CAuthenticator from "../../../application/authentication/azure-b2c-authenticator";
import { DocumentNode } from "graphql";

var authenticator = AzureB2CAuthenticator.getInstance();

export interface LookupDropDownProps {
    dataFromParent: any;
    id: string;
    name: string;
    title: string;
    query: DocumentNode;
    mapQueryResult: (data: any) => LookupDropDownItem;
    isError?: boolean;
}

export interface LookupDropDownItem {
    id: string;
    name: string;
}

export const LookupDropDown = (props: any) => {
    const { id, query, name, title, mapQueryResult, dataFromParent, isError } = props;

    const [currentValue, setCurrentValue] = useState(dataFromParent.id);
    const [errorClassname, setErrorClassname] = useState("");

    React.useEffect(() => {
        if (isError) {
            setErrorClassname("is-danger");
        } else {
            setErrorClassname("");
        }
    }, [isError]);

    // Sets page size
    const [pageSize] = useState(-1);
    const [pageIndex] = useState(-1);

    // Gets data and sets related states
    const { loading, error, data } = useQuery(query, {
        variables: { pageSize, pageIndex },
        fetchPolicy: 'network-only'
    });

    /*
     * Sets input state changes dynamicly through name attribute
     */
    const handleInputChange = (e: any) => {
        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;

        // Sets state
        setCurrentValue(value);

        // Send input up state
        props.onChange(mapQueryResult(data)[mapQueryResult(data).findIndex((x: any) => x.id === value)]);
    };

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <Form.Control>
            <Form.Label htmlFor={props.id}>{props.title}</Form.Label>
            {loading ? (
                <>Loading...</>
            ) : (
                <div className={`select is-fullwidth ${errorClassname}`}>
                    <Form.Select id={props.id} className="is-fullwidth" name={props.name} value={currentValue} onChange={handleInputChange}>
                        <option value="">Select a value from the list</option>
                        {!loading && mapQueryResult(data).length !== 0
                            ? mapQueryResult(data).map((item: any, index: number) => {
                                  if (item.suffix) {
                                      return (
                                          <option key={index} value={item.id}>
                                              {item.name} ({item.suffix})
                                          </option>
                                      );
                                  } else {
                                      return (
                                          <option key={index} value={item.id}>
                                              {item.name}
                                          </option>
                                      );
                                  }
                              })
                            : ""}
                    </Form.Select>
                </div>
            )}
        </Form.Control>
    );
};
