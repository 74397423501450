import gql from 'graphql-tag';

export const GET_COMMODITIES = gql`
    query commodities ($pageSize: Int!, $pageIndex: Int!) {
        commodities (pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
            }
            total
        }
    }
`;

export const GET_COMMODITY = gql`
    query commodity ($id: ID!) {
        commodity (id: $id) {
            id
            name
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_COMMODITY = gql`
    mutation createCommodity ($commodity: commodityCreateInput!) {
        createCommodity (commodity: $commodity) {
            id
            name
            updated
            created
        }
    }
`;

export const UPDATE_COMMODITY = gql`
    mutation updateCommodity ($commodity: commodityUpdateInput!, $updated: DateTime!) {
        updateCommodity (commodity: $commodity, updated: $updated) {
            id
            name
            updated
            created
        }
    }
`;

export const DELETE_COMMODITY = gql`
    mutation deleteCommodity ($id: ID!){
        deleteCommodity (id: $id)
    }
`;