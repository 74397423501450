import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Columns, Form, Level, Button, Heading, Notification } from 'react-bulma-components';
import { AdminSidebar } from './../shared/AdminSidebar';
import { Icon } from '../../../elements/Icon';
import { mdiArrowLeft, mdiContentSaveOutline } from '@mdi/js';
import { CREATE_COUNTRY } from '../../../../database/countries';

export const AdminCountryCreate = (props: any) => {

    // History is used to redirect users after save/edit/delete
    const history = useHistory();

    // Redirect path
    const redirectTo = '/admin/countries';

    // Adds data to state
    const [data, setData] = useState({} as any);

    // Define create mutation
    const [createCountry, { loading, error }] = useMutation(CREATE_COUNTRY, {
        onCompleted: (data) => handleUpdateSuccess()
    });

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {
        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // Get the current data set
        const input: any = { ...data };

        // Sets data key with a es6 computed property name
        // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Operators/Object_initializer#Computed_property_names
        input[name] = value;

        // Sets state
        setData(input);
    }

    const [validationError, setValidationError] = useState({} as any);

    const handleValidation = (country: any) => {
        // Validation patterns
        const isEmpty = (field: any) => field === "" || field == null;
        const error = {} as any;

        if (isEmpty(country.name)) {
            error['name'] = "Name cannot be empty";
        }

        if (isEmpty(country.famCode)) {
            error['famCode'] = "CC FAM Code cannot be empty";
        }

        setValidationError(error);

        for (var key in error) {
            if (error.hasOwnProperty(key))
                return false;
        }

        return true;
    }

    /*
    * Takes care of the submitted data and saves/updates
    */
    const handleSubmit = (event: any) => {

        event.preventDefault();

        // Define data set
        const country = {
            name: data.name,
            famCode: data.famCode
        }

        // Validation
        if (handleValidation(country) === false) {
            return;
        }

        // Saves data and calls handleUpdateSuccess()
        createCountry({
            variables: { country: country }
        });
    }

    /*
    * Takes care of the what happens after data has been updated
    */
    const handleUpdateSuccess = () => {

        // Clear existing data
        setData({});

        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { created: true }
        });

    }

    /*
     * isEmpty Helper function
     */
    const isEmpty = (obj: any) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    {/* Level */}
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Button onClick={history.goBack} color="primary" size="small">
                                        <Icon path={mdiArrowLeft} color="#fff" />
                                        <strong>Back</strong>
                                    </Button>
                                </Level.Item>
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">Create a country</Heading>
                                </Level.Item>
                            </Level.Side>
                        </Level >
                    </div>

                    {/* Page content */}
                    <div className="box">

                        {error && (
                            <Notification color="danger">
                                <strong>Error:</strong> Please try again
                            </Notification>
                        )}

                        {!isEmpty(validationError) && (
                            <div className="notification is-danger mb-5">
                                <ul>
                                    {
                                        Object.keys(validationError).map((item: any, i) => (
                                            <li key={i}>{validationError[item]}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )}

                        {/* Form */}
                        <form onSubmit={handleSubmit}>

                            <Columns>
                                <Columns.Column size={4}>
                                    <Form.Control>
                                        <Form.Label htmlFor="name">Name</Form.Label>
                                        <Form.Input id="name" name="name" value={data.name} onChange={handleInputChange} autoComplete="off" />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={4}>
                                    <Form.Control>
                                        <Form.Label htmlFor="famCode">CC FAM Code</Form.Label>
                                        <Form.Input id="famCode" name="famCode" value={data.famCode} onChange={handleInputChange} autoComplete="off" />
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            {/* Form save button */}
                            <Form.Field>
                                <Form.Control className="is-clearfix">
                                    {loading ? (
                                        <Button className="is-pulled-left is-loading" color="primary" size="small" disabled="true">
                                            <span>Loading</span>
                                        </Button>
                                    ):(
                                        <Button type="submit" className="is-pulled-left" color="primary" size="small">
                                            <Icon path={mdiContentSaveOutline} color="#fff" />
                                            <span>Save</span>
                                        </Button>
                                    )}

                                </Form.Control>

                            </Form.Field>

                        </form>

                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
}
