import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import queryString from "query-string";

// Apollo
import { 
    ApolloClient, 
    ApolloProvider, 
    InMemoryCache, 
    ApolloLink, 
    Observable, 
    HttpLink
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

// Components
import { Container, Section } from "react-bulma-components";

// Pages and parts
import { Header } from "./components/shared/Header";

import { ForeignMachinesIndex } from "./components/pages/machine/ForeignMachinesIndex";
import { ForeignMachinesEdit } from "./components/pages/machine/ForeignMachinesEdit";
import { ForeignMachinesCreate } from "./components/pages/machine/ForeignMachinesCreate";

import { AdminIndex } from "./components/pages/admin/AdminIndex";
import { AdminImport } from "./components/pages/admin/import/AdminImport";

import { AdminBrandIndex } from "./components/pages/admin/brand/AdminBrandIndex";
import { AdminBrandEdit } from "./components/pages/admin/brand/AdminBrandEdit";
import { AdminBrandCreate } from "./components/pages/admin/brand/AdminBrandCreate";

import { AdminCountryIndex } from "./components/pages/admin/country/AdminCountryIndex";
import { AdminCountryEdit } from "./components/pages/admin/country/AdminCountryEdit";
import { AdminCountryCreate } from "./components/pages/admin/country/AdminCountryCreate";

import { AdminSegmentIndex } from "./components/pages/admin/segment/AdminSegmentIndex";
import { AdminSegmentEdit } from "./components/pages/admin/segment/AdminSegmentEdit";
import { AdminSegmentCreate } from "./components/pages/admin/segment/AdminSegmentCreate";

import { AdminRockConditionIndex } from "./components/pages/admin/rockcondition/AdminRockConditionIndex";
import { AdminRockConditionEdit } from "./components/pages/admin/rockcondition/AdminRockConditionEdit";
import { AdminRockConditionCreate } from "./components/pages/admin/rockcondition/AdminRockConditionCreate";

import { AdminMachineTypeIndex } from "./components/pages/admin/machinetype/AdminMachineTypeIndex";
import { AdminMachineTypeEdit } from "./components/pages/admin/machinetype/AdminMachineTypeEdit";
import { AdminMachineTypeCreate } from "./components/pages/admin/machinetype/AdminMachineTypeCreate";

import { AdminRdtIndex } from "./components/pages/admin/rdt/AdminRdtIndex";
import { AdminRdtEdit } from "./components/pages/admin/rdt/AdminRdtEdit";
import { AdminRdtCreate } from "./components/pages/admin/rdt/AdminRdtCreate";

import { AdminCommodityIndex } from "./components/pages/admin/commodity/AdminCommodityIndex";
import { AdminCommodityCreate } from "./components/pages/admin/commodity/AdminCommodityCreate";
import { AdminCommodityEdit } from "./components/pages/admin/commodity/AdminCommodityEdit";

import AuthorizedArea from "./components/AuthorizedArea";
import AdminRoute from "./components/AdminRoute";

import AzureB2CAuthenticator, { StorageLocation, Identity } from "./application/authentication/azure-b2c-authenticator";
import MolUserService from "./application/authentication/mol-user-service";

// Styles
import "./resources/assets/sass/app.sass";


function App() {
    var authenticator = AzureB2CAuthenticator.getInstance();
    
    authenticator.initialize({
        molService: new MolUserService(process.env.REACT_APP_MOL_SERVICE_URL ?? ""),
        tenant: process.env.REACT_APP_B2C_TENANT ?? "",
        policy: process.env.REACT_APP_B2C_POLICY ?? "",
        clientId: process.env.REACT_APP_B2C_CLIENT_ID ?? "",
        redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI ?? "",
        idScopes: process.env.REACT_APP_B2C_ID_SCOPES?.split(", ") ?? [],
        accessScopes: process.env.REACT_APP_B2C_ACCESS_SCOPES?.split(", ") ?? [],
        storageLocation: StorageLocation.LocalStorage,
        onLoginSuccess: (identity: Identity) => {
            setIsAuthenticated(true);
            setHasFamAccess(authenticator.hasFamAccess());
            setIdentity(identity);
        },
    });
    
    const [isAuthenticated, setIsAuthenticated] = useState(authenticator.isAuthenticated());
    const [identity, setIdentity] = useState(authenticator.getIdentity());
    const [hasFamAccess, setHasFamAccess] = useState(authenticator.hasFamAccess());
    
    const query = queryString.parse(window.location.search);
    
    const headers = (token: string) => {
        return {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        };
    };
    
    const logout = () => {
        console.log("logout");
        authenticator.logout.bind(authenticator);
        //authenticator.logout();
        window.location.reload();
    };
    
    const getToken = async () => {
        let identity = await AzureB2CAuthenticator.getInstance().getIdentity();
        const token = identity?.idToken;
        
        return token;
        
        // operation.setContext({
        //     headers: headers(token ?? ""),
        // });
    };
    
    const request = async (operation: any) => {
        const token = await getToken();
        operation.setContext({
            headers: {
                authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        });
    };
    
    const requestLink = new ApolloLink((operation, forward) =>
        new Observable(observer => {
            let handle: any;
            Promise.resolve(operation)
            .then(oper => request(oper))
            .then(() => {
                handle = forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            })
            .catch(observer.error.bind(observer));
            
            return () => {
                if (handle) handle.unsubscribe();
            };
        })
    );
    
    const client = new ApolloClient({
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) => {
                        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
                    })
                };
                
                if (networkError) {
                    console.log(`[Network error]: ${networkError}`);
                    //logout();
                }
                    
            }),
            requestLink,
            new HttpLink({
                uri: process.env.REACT_APP_APOLLO_URL,
                credentials: 'same-origin'
            })
        ]),
        cache: new InMemoryCache(),
        });
        
        return (
            <ApolloProvider client={client}>
            <Router>
            <Header identity={identity} isSignedIn={isAuthenticated} hasFamAccess={hasFamAccess} signoutFunc={authenticator.logout.bind(authenticator)} />
            <Section>
            {isAuthenticated && !hasFamAccess ? (
                <div>
                The logged in user has no access to any CC FAM Codes, which is required to use the Foreign Equipment application.
                <br />
                Please log out and try with another user or contact helpdesk for assistance.
                </div>
                ) : (
                    <></>
                    )}
                    <AuthorizedArea autoSignin={!!query.sso}>
                    <Container>
                    <Switch>
                    <AdminRoute identity={identity} path="/admin/brands" component={AdminBrandIndex} exact />
                    <AdminRoute identity={identity} path="/admin/brands/brand/:id" component={AdminBrandEdit} exact />
                    <AdminRoute identity={identity} path="/admin/brands/create" component={AdminBrandCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/countries" component={AdminCountryIndex} exact />
                    <AdminRoute identity={identity} path="/admin/countries/country/:id" component={AdminCountryEdit} exact />
                    <AdminRoute identity={identity} path="/admin/countries/create" component={AdminCountryCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/segments" component={AdminSegmentIndex} exact />
                    <AdminRoute identity={identity} path="/admin/segments/segment/:id" component={AdminSegmentEdit} exact />
                    <AdminRoute identity={identity} path="/admin/segments/create" component={AdminSegmentCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/commodities" component={AdminCommodityIndex} exact />
                    <AdminRoute identity={identity} path="/admin/commodities/commodity/:id" component={AdminCommodityEdit} exact />
                    <AdminRoute identity={identity} path="/admin/commodities/create" component={AdminCommodityCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/rdts" component={AdminRdtIndex} exact />
                    <AdminRoute identity={identity} path="/admin/rdts/rdt/:id" component={AdminRdtEdit} exact />
                    <AdminRoute identity={identity} path="/admin/rdts/create" component={AdminRdtCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/rockconditions" component={AdminRockConditionIndex} exact />
                    <AdminRoute identity={identity} path="/admin/rockconditions/rockcondition/:id" component={AdminRockConditionEdit} exact />
                    <AdminRoute identity={identity} path="/admin/rockconditions/create" component={AdminRockConditionCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/machinetypes" component={AdminMachineTypeIndex} exact />
                    <AdminRoute identity={identity} path="/admin/machinetypes/machinetype/:id" component={AdminMachineTypeEdit} exact />
                    <AdminRoute identity={identity} path="/admin/machinetypes/create" component={AdminMachineTypeCreate} exact />
                    
                    <AdminRoute identity={identity} path="/admin/import" component={AdminImport} />
                    <AdminRoute identity={identity} path="/admin" component={AdminIndex} />
                    
                    <Route path="/foreignmachine/create" component={ForeignMachinesCreate} exact />
                    <Route path="/foreignmachine/:id" component={ForeignMachinesEdit} />
                    <Route path="/" component={ForeignMachinesIndex} />
                    </Switch>
                    </Container>
                    </AuthorizedArea>
                    </Section>
                    </Router>
                    </ApolloProvider>
                    );
                }
                
                export default App;
                