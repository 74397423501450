import React, { useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Columns, Form, Level, Button, Heading, Notification } from "react-bulma-components";
import { LookupDropDown, LookupDropDownItem } from "../../elements/Fields/LookupDropDown";
import { Icon } from "../../elements/Icon";
import { mdiArrowLeft, mdiCheck, mdiContentSaveOutline, mdiPencil } from "@mdi/js";
import { AutocompleteInput, AutocompleteItem } from "../../AutocompleteInput";
import { SubEquipment } from "./SubEquipment";
import { dateFormat } from "../../../config/app";
import DatePicker from "react-datepicker";
import { CREATE_MACHINE, GET_MACHINE, GET_MACHINES_MODELS } from "../../../database/foreignmachines";
import { GET_BRANDS } from "../../../database/brands";
import { GET_MACHINE_TYPES } from "../../../database/machinetypes";
import { GET_COUNTRIES } from "../../../database/countries";
import { GET_ROCK_CONDITIONS } from "../../../database/rockconditions";
import { GET_SEGMENTS } from "../../../database/segments";
import AzureB2CAuthenticator from "../../../application/authentication/azure-b2c-authenticator";
import { Console } from "console";
import { GET_RDTS } from "../../../database/rdt";
import { GET_COMMODITIES } from "../../../database/commodity";

export const ForeignMachinesCreate = (props: any) => {
    // History is used to redirect users after save/edit/delete
    const history = useHistory();

    // Authentication
    const authenticator = AzureB2CAuthenticator.getInstance();
    const identity = authenticator.getIdentity();

    // Redirect path
    const redirectTo = "/";

    const { data: queryData, loading: machineLoading } = useQuery(GET_MACHINE, {
        variables: { id: null },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            //console.log("checking id data is null ", data)
            if (data) {
                //console.log("data loaded : ", data)

                setData({ foreignMachine: data.foreignMachine });
            }
        },
    });

    // Adds data and validation to state
    const [data, setData] = useState({ foreignMachine: {} } as any);
    const [validationError, setValidationError] = useState({} as any);

    const [serialEditable, setSerialEditable] = useState(false);

    //console.log(data);

    // Define create mutation
    const [createForeignMachine, { loading, error }] = useMutation(CREATE_MACHINE, {
        onCompleted: (data) => handleUpdateSuccess(),
        onError: (error) => {
            handleError(error);
        },
    });

    /*
     * Sets input state changes dynamicly through name attribute
     */
    const handleInputChange = (e: any) => {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        const input: any = { ...data.foreignMachine };

        input[name] = value;

        // Sets state
        setData({ foreignMachine: input });
    };

    /*
     * Validation
     */
    const validateFields = (foreignMachine: any) => {
        // Validation patterns
        const isEmpty = (field: any) => field === "" || field == null;
        const error = {} as any;

        if (isEmpty(foreignMachine.serialNumber)) {
            error["serialNumber"] = "Serial number cannot be empty";
        }

        if (isEmpty(foreignMachine.model)) {
            error["model"] = "Model cannot be empty";
        }

        if (isEmpty(foreignMachine.brandName)) {
            error["brand"] = "Brand cannot be empty";
        }

        if (isEmpty(foreignMachine.machineTypeName)) {
            error["machinetype"] = "Machine Type cannot be empty";
        }

        if (isEmpty(foreignMachine.countryId)) {
            error["country"] = "Country cannot be empty";
        }

        if (isEmpty(foreignMachine.worksite)) {
            error["worksite"] = "Worksite cannot be empty";
        }

        setValidationError(error);

        for (var key in error) {
            if (error.hasOwnProperty(key)) return false;
        }

        return true;
    };

    /*
     * Takes care of the submitted data and saves/updates
     */
    const handleSubmit = (event: any) => {
        event.preventDefault();

        // Define data set
        const foreignMachine = {
            serialNumber: data.foreignMachine.serialNumber, //String
            worksite: data.foreignMachine.worksite, //String
            operationalStatusCode: data.foreignMachine.operationalStatusCode, //String
            manufacturingDate: data.foreignMachine.manufacturingDate, //DateTime
            //inputSource: data.foreignMachine.inputSource, //String
            utilization: data.foreignMachine.utilization, // Int
            dateOfVisiting: data.foreignMachine.dateOfVisiting, //DateTime
            rdtDrilledMetersPerYear: data.foreignMachine.rdtDrilledMetersPerYear, //String
            rdtRatio: data.foreignMachine.rdtRatio, // Boolean
            rdtCostPerMeter: data.foreignMachine.rdtCostPerMeter, //String
            //rdtProductLine: data.foreignMachine.rdtProductLine, //String
            rdtBrandOfConsumable: data.foreignMachine.rdtBrandOfConsumable, //String
            rdtEnabled: data.foreignMachine.rdtEnabled, // Boolean
            runningCostPerHour: data.foreignMachine.runningCostPerHour, //String

            //model: data.foreignMachine.model, //String
            localSerialNumber: data.foreignMachine.localSerialNumber, //String
            localName: data.foreignMachine.localName, //String
            customerName: data.foreignMachine.customerName, //String
            customerNumber: data.foreignMachine.customerNumber, //String
            stateOrRegion: data.foreignMachine.stateOrRegion, //String
            town: data.foreignMachine.town, //String
            responsible: data.foreignMachine.responsible, //String
            dieselEngineHours: data.foreignMachine.dieselEngineHours, //String
            dieselEngineHoursUpdatedDate: data.foreignMachine.dieselEngineHoursUpdatedDate, // DateTime
            dieselEngineHoursReadDate: data.foreignMachine.dieselEngineHoursReadDate, // DateTime
            impactHours: data.foreignMachine.impactHours, //String
            impactHoursUpdatedDate: data.foreignMachine.impactHoursUpdatedDate, // DateTime
            impactHoursReadDate: data.foreignMachine.impactHoursReadDate, // DateTime

            countryId: data.foreignMachine.country ? data.foreignMachine.country.id : null, // Id

            machineTypeId: data.foreignMachine.machineType ? data.foreignMachine.machineType.id : null, // Id
            machineTypeName: data.foreignMachine.machineType ? data.foreignMachine.machineType.name : null, // String

            model: data.foreignMachine.model ? data.foreignMachine.model : null, // String

            brandId: data.foreignMachine.brand ? data.foreignMachine.brand.id : null, // Id
            brandName: data.foreignMachine.brand ? data.foreignMachine.brand.name : null,
            segmentId: data.foreignMachine.segment ? data.foreignMachine.segment.id : null, // Id
            rockConditionId: data.foreignMachine.rockCondition ? data.foreignMachine.rockCondition.id : null, // Id
            rdtProductLineId: data.foreignMachine.rdtProductLine ? data.foreignMachine.rdtProductLine.id : null, // Id
            commodityId: data.foreignMachine.commodity ? data.foreignMachine.commodity.id : null, // Id

            subequipmentSerialNumber1: data.foreignMachine.subequipmentSerialNumber1,
            subequipmentSerialNumber2: data.foreignMachine.subequipmentSerialNumber2,
            subequipmentSerialNumber3: data.foreignMachine.subequipmentSerialNumber3,
            subequipmentSerialNumber4: data.foreignMachine.subequipmentSerialNumber4,

            subequipmentComponentType1: data.foreignMachine.subequipmentComponentType1,
            subequipmentComponentType2: data.foreignMachine.subequipmentComponentType2,
            subequipmentComponentType3: data.foreignMachine.subequipmentComponentType3,
            subequipmentComponentType4: data.foreignMachine.subequipmentComponentType4,

            subequipmentBrand1: data.foreignMachine.subequipmentBrand1,
            subequipmentBrand2: data.foreignMachine.subequipmentBrand2,
            subequipmentBrand3: data.foreignMachine.subequipmentBrand3,
            subequipmentBrand4: data.foreignMachine.subequipmentBrand4,
        };

        console.log(foreignMachine);

        // Validation
        if (validateFields(foreignMachine) === false) {
            return;
        }

        // Saves data and calls handleUpdateSuccess()
        createForeignMachine({
            variables: { foreignMachine: foreignMachine },
        });
    };

    /*
     * Takes care of the what happens after data has been updated
     */
    const handleUpdateSuccess = () => {
        // Clear existing data
        setData({ foreignMachine: {} });

        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { created: true },
        });
    };

    /*
     * Takes care of what happens after error from API
     */
    const handleError = (errorMsg: any) => {
        const graphQLGeneratedErrorMsg = errorMsg.graphQLErrors[0].message;
        const graphQLduplicatedErrorMsg = "GraphQL.ExecutionError: Entry already exists";
        const duplicatedErrorMsgExplained =
            ". A possible collision between Serial Number and Country (CC FAM Code). Double check that the combination of Serial Number and Country (CC FAM Code) does not already exist.";
        const error = {} as any;

        if (graphQLGeneratedErrorMsg == graphQLduplicatedErrorMsg) {
            error["gqlError"] = graphQLGeneratedErrorMsg + duplicatedErrorMsgExplained;
            error["serialNumber"] = " "; //trigger error on specific input field
            error["country"] = " "; //trigger error on specific input field
        } else {
            error["gqlError"] = graphQLGeneratedErrorMsg + ". Error: Please try again";
        }

        setValidationError(error);

        for (var key in error) {
            if (error.hasOwnProperty(key)) return false;
        }

        return true;
    };

    /*
     * Takes you back to list
     */
    const goBack = () => {
        // Redirect to list with state
        history.push({
            pathname: redirectTo,
        });
    };

    /*
     * Edit serial code
     */
    const editSerial = (event: any) => {
        event.preventDefault();

        // Clear serial if serial is temporary; pattern fidxxxxxxx
        if (data.foreignMachine.serialNumber.startsWith("FID")) {
            const input: any = { ...data.foreignMachine };
            input["serialNumber"] = "";
            setData({ foreignMachine: input });
        }

        setSerialEditable((serialEditable) => !serialEditable);
    };

    /*
     * isEmpty Helper function
     */
    const isEmpty = (obj: any) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    if (machineLoading) return <div>Loading...</div>;

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column>
                    {/* Level */}
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Button onClick={goBack} color="primary" size="small">
                                        <Icon path={mdiArrowLeft} color="#fff" />
                                        <strong>Back</strong>
                                    </Button>
                                </Level.Item>
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">
                                        Create a machine
                                    </Heading>
                                </Level.Item>
                            </Level.Side>
                        </Level>
                    </div>

                    {/* Page content */}
                    <div className="box">
                        {!isEmpty(validationError) && (
                            <div className="notification is-danger mb-5">
                                <ul>
                                    {Object.keys(validationError).map((item: any, i) => (
                                        <li key={i}>{validationError[item]}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Form */}
                        <form onSubmit={handleSubmit} autoComplete="off">
                            <Columns>
                                <Columns.Column size={3}>
                                    <Form.Control className="is-relative">
                                        <Form.Label htmlFor="serialNumber">Serial Number</Form.Label>
                                        <Form.Input
                                            id="serialNumber"
                                            name="serialNumber"
                                            value={data.foreignMachine.serialNumber ? data.foreignMachine.serialNumber : ""}
                                            onChange={handleInputChange}
                                            className={validationError.serialNumber ? "is-danger pr-5" : "pr-5"}
                                            disabled={serialEditable ? false : true}
                                        />
                                        <Button onClick={editSerial} size="small" className="editSerial" title="Click to enter real Serial number if available">
                                            {!serialEditable ? <Icon path={mdiPencil} color="#363636" /> : <Icon path={mdiCheck} color="#363636" />}
                                        </Button>
                                    </Form.Control>
                                    <p className="help">Actual if available</p>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="brand.name">Brand</Form.Label>
                                        <AutocompleteInput
                                            id="brand"
                                            query={GET_BRANDS}
                                            name="brand"
                                            pick={-1}
                                            mapQueryResult={(data: any): AutocompleteItem => {
                                                return data.brands.items.map((item: any) => {
                                                    return {
                                                        id: item.id,
                                                        name: item.name,
                                                        __typename: "Brand",
                                                    };
                                                });
                                            }}
                                            value={data.brand ? data.brand.name : ""}
                                            onItemSelected={(brand: any) => {
                                                console.log("brand:", brand);
                                                data.foreignMachine["brand"] = brand;
                                            }}
                                            onChange={(e: any) => {
                                                const val = e.target.value;
                                                data.foreignMachine["brand"] = {
                                                    name: val,
                                                    id: "00000000-0000-0000-0000-000000000000",
                                                    __typename: "Brand",
                                                };
                                            }}
                                            className={validationError.brand ? "input is-danger" : "input"}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="model">Model</Form.Label>
                                        <AutocompleteInput
                                            id="model"
                                            query={GET_MACHINES_MODELS}
                                            pick={-1}
                                            name="model"
                                            distinct={true}
                                            mapQueryResult={(data: any): AutocompleteItem => {
                                                return data.foreignMachines.items.map((item: any, index: number) => {
                                                    return { name: item.model };
                                                });
                                            }}
                                            value={data.model ? data.model.name : ""}
                                            onItemSelected={(model: any) => {
                                                data.foreignMachine["model"] = model.name;
                                            }}
                                            onChange={(e: any) => {
                                                const val = e.target.value;
                                                data.foreignMachine["model"] = val;
                                            }}
                                            className={validationError.model ? "input is-danger" : "input"}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="machinetype">Machine Type</Form.Label>
                                        <AutocompleteInput
                                            id="machinetype"
                                            query={GET_MACHINE_TYPES}
                                            name="machinetype"
                                            pick={-1}
                                            mapQueryResult={(data: any): AutocompleteItem => {
                                                return data.machineTypes.items.map((item: any) => {
                                                    return {
                                                        id: item.id,
                                                        name: item.name,
                                                        rdtEnabled: item.rdtEnabled,
                                                        __typename: "MachineType",
                                                    };
                                                });
                                            }}
                                            value={data.machineType ? data.machineType.name : ""}
                                            onItemSelected={(machineType: any) => {
                                                data.foreignMachine["machineType"] = machineType;

                                                // Updated rdtEnabled state on select
                                                const input = { ...data.foreignMachine };
                                                input["rdtEnabled"] = machineType.rdtEnabled;
                                                setData({ foreignMachine: input });
                                            }}
                                            onChange={(e: any) => {
                                                const val = e.target.value;

                                                if (val.length === 0) {
                                                    data.foreignMachine["machineType"] = null;
                                                } else {
                                                    data.foreignMachine["machineType"] = {
                                                        name: val,
                                                        id: "00000000-0000-0000-0000-000000000000",
                                                        __typename: "MachineType",
                                                    };
                                                }
                                            }}
                                            className={validationError.machinetype ? "input is-danger" : "input"}
                                        />
                                    </Form.Control>
                                </Columns.Column>

                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="localName">Local machine name</Form.Label>
                                        <Form.Input
                                            id="localName"
                                            name="localName"
                                            value={data.foreignMachine.localName ? data.foreignMachine.localName : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>

                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="localSerialNumber">Local serial number</Form.Label>
                                        <Form.Input
                                            id="localSerialNumber"
                                            name="localSerialNumber"
                                            value={data.foreignMachine.localSerialNumber ? data.foreignMachine.localSerialNumber : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>

                                <Columns.Column size={3}>
                                    <Form.Control className="is-expanded">
                                        <Form.Label htmlFor="manufacturingDate">Manufactured date</Form.Label>
                                        <DatePicker
                                            className="input"
                                            id="manufacturingDate"
                                            name="manufacturingDate"
                                            selected={data.foreignMachine.manufacturingDate ? new Date(data.foreignMachine.manufacturingDate) : null}
                                            onChange={(date: any) => {
                                                let input = { ...data.foreignMachine };
                                                input["manufacturingDate"] = date.toISOString();
                                                setData({ foreignMachine: input });
                                            }}
                                            dateFormat={dateFormat}
                                            showYearDropdown
                                        />
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            <hr />

                            <Columns>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="customerName">Customer name</Form.Label>
                                        <Form.Input
                                            id="customerName"
                                            name="customerName"
                                            value={data.foreignMachine.customerName ? data.foreignMachine.customerName : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="customerNumber">Customer number</Form.Label>
                                        <Form.Input
                                            id="customerNumber"
                                            name="customerNumber"
                                            value={data.foreignMachine.customerNumber ? data.foreignMachine.customerNumber : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="stateOrRegion">State or region</Form.Label>
                                        <Form.Input
                                            id="stateOrRegion"
                                            name="stateOrRegion"
                                            value={data.foreignMachine.stateOrRegion ? data.foreignMachine.stateOrRegion : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="town">Town</Form.Label>
                                        <Form.Input id="town" name="town" value={data.foreignMachine.town ? data.foreignMachine.town : ""} onChange={handleInputChange} />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="country">Country (CC FAM Code)</Form.Label>
                                        <AutocompleteInput
                                            id="country"
                                            query={GET_COUNTRIES}
                                            pick={-1}
                                            name="country"
                                            mapQueryResult={(data: any): AutocompleteItem => {
                                                return data.countries.items
                                                    .filter((item: any) => {
                                                        return identity?.famCodeAccess.includes(item.famCode);
                                                    })
                                                    .map((item: any, index: number) => {
                                                        return {
                                                            id: item.id,
                                                            name: `${item.name} (${item.famCode})`,
                                                            __typename: "Country",
                                                        };
                                                    });
                                            }}
                                            value={data.country ? `${data.country.name} (${data.country.famCode})` : ""}
                                            onItemSelected={(country: any) => {
                                                data.foreignMachine["country"] = country;
                                            }}
                                            onChange={() => {}}
                                            className={validationError.country ? "input is-danger" : "input"}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="worksite">Worksite</Form.Label>
                                        <Form.Input
                                            id="worksite"
                                            name="worksite"
                                            value={data.foreignMachine.worksite ? data.foreignMachine.worksite : ""}
                                            onChange={handleInputChange}
                                            className={validationError.worksite ? "input is-danger" : "input"}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="responsible">Responsible</Form.Label>
                                        <Form.Input
                                            id="responsible"
                                            name="responsible"
                                            value={data.foreignMachine.responsible ? data.foreignMachine.responsible : ""}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            <hr />

                            <Columns>
                                <Columns.Column size={3}>
                                    <LookupDropDown
                                        dataFromParent={data.foreignMachine.rockCondition ? data.foreignMachine.rockCondition : ""}
                                        id="rockCondition"
                                        query={GET_ROCK_CONDITIONS}
                                        name="rockCondition"
                                        title="Rock Condition"
                                        mapQueryResult={(data: any): LookupDropDownItem => {
                                            return data.rockConditions.items.map((item: any) => {
                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    __typename: "RockCondition",
                                                };
                                            });
                                        }}
                                        onChange={(obj: any) => {
                                            const input = { ...data.foreignMachine };

                                            input["rockCondition"] = obj;
                                            // Sets state
                                            setData({ foreignMachine: input });
                                        }}
                                    />
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <LookupDropDown
                                        dataFromParent={data.foreignMachine.segment ? data.foreignMachine.segment : ""}
                                        id="segment"
                                        query={GET_SEGMENTS}
                                        name="segment"
                                        title="Segment"
                                        mapQueryResult={(data: any): LookupDropDownItem => {
                                            return data.segments.items.map((item: any) => {
                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    __typename: "Segment",
                                                };
                                            });
                                        }}
                                        onChange={(obj: any) => {
                                            const input = { ...data.foreignMachine };

                                            input["segment"] = obj;
                                            // Sets state
                                            setData({ foreignMachine: input });
                                        }}
                                    />
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Label htmlFor="utilization">Utilization</Form.Label>
                                    <Form.Field kind="addons">
                                        <Form.Input
                                            id="utilization"
                                            name="utilization"
                                            type="number"
                                            value={data.foreignMachine.utilization ? data.foreignMachine.utilization : ""}
                                            min="0"
                                            max="100"
                                            onChange={handleInputChange}
                                        />
                                        <p className="control">
                                            <span className="button is-static">%</span>
                                        </p>
                                    </Form.Field>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control className="is-expanded">
                                        <Form.Label htmlFor="dateOfVisiting">Date of Visit</Form.Label>
                                        <DatePicker
                                            className="input"
                                            id="dateOfVisiting"
                                            name="dateOfVisiting"
                                            selected={data.foreignMachine.dateOfVisiting ? new Date(data.foreignMachine.dateOfVisiting) : null}
                                            onChange={(date: any) => {
                                                let input = { ...data.foreignMachine };
                                                input["dateOfVisiting"] = date.toISOString();
                                                setData({ foreignMachine: input });
                                            }}
                                            dateFormat={dateFormat}
                                            showYearDropdown
                                        />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label>Operational status</Form.Label>
                                        <input
                                            className="is-checkradio"
                                            id="operationalStatusCode"
                                            type="checkbox"
                                            name="operationalStatusCode"
                                            checked={data.foreignMachine.operationalStatusCode ?? false}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="operationalStatusCode">Active</label>
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <Form.Control>
                                        <Form.Label htmlFor="runningCostPerHour">Machine Running cost / hours, SEK</Form.Label>
                                        <Form.Input
                                            id="runningCostPerHour"
                                            name="runningCostPerHour"
                                            value={data.foreignMachine.runningCostPerHour ? data.foreignMachine.runningCostPerHour : ""}
                                            onChange={handleInputChange}
                                            type="number"
                                        />
                                    </Form.Control>
                                    <Form.Help>Use a dot as decimal separator if applicable</Form.Help>
                                </Columns.Column>
                                <Columns.Column size={3}>
                                    <LookupDropDown
                                        dataFromParent={data.foreignMachine.commodity ? data.foreignMachine.commodity : ""}
                                        id="commodity"
                                        query={GET_COMMODITIES}
                                        name="commodity"
                                        title="Commodity"
                                        mapQueryResult={(data: any): LookupDropDownItem => {
                                            return data.commodities.items.map((item: any) => {
                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                    __typename: "Commodity",
                                                };
                                            });
                                        }}
                                        onChange={(obj: any) => {
                                            const input = { ...data.foreignMachine };

                                            input["commodity"] = obj;
                                            // Sets state
                                            setData({ foreignMachine: input });
                                        }}
                                    />
                                </Columns.Column>
                            </Columns>

                            {/* Display RDT if it's enabled */}
                            <Columns>
                                <Columns.Column>
                                    <Form.Control>
                                        <input
                                            className="is-checkradio"
                                            id="rdtEnabled"
                                            type="checkbox"
                                            name="rdtEnabled"
                                            checked={data.foreignMachine.rdtEnabled ?? false}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="rdtEnabled">RDT enabled</label>
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            {data.foreignMachine.rdtEnabled ? (
                                <div className="box is-light">
                                    <Columns>
                                        <Columns.Column size={3}>
                                            <Form.Control>
                                                <Form.Label htmlFor="rdtDrilledMetersPerYear">Drilled meters per year</Form.Label>
                                                <Form.Input
                                                    id="rdtDrilledMetersPerYear"
                                                    name="rdtDrilledMetersPerYear"
                                                    value={data.foreignMachine.rdtDrilledMetersPerYear ? data.foreignMachine.rdtDrilledMetersPerYear : ""}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                            </Form.Control>
                                            <Form.Help>Use a dot as decimal separator if applicable</Form.Help>
                                        </Columns.Column>
                                        <Columns.Column size={3}>
                                            <Form.Control>
                                                <Form.Label htmlFor="rdtRatio">RDT 1:1 Ratio</Form.Label>
                                                <Form.Select
                                                    className="is-fullwidth"
                                                    id="rdtRatio"
                                                    name="rdtRatio"
                                                    value={data.foreignMachine.rdtRatio ? data.foreignMachine.rdtRatio : "false"}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="false">False</option>
                                                    <option value="true">True</option>
                                                </Form.Select>
                                            </Form.Control>
                                        </Columns.Column>
                                        <Columns.Column size={3}>
                                            <Form.Control>
                                                <Form.Label htmlFor="rdtCostPerMeter">Consumable Cost / Meter, SEK</Form.Label>
                                                <Form.Input
                                                    id="rdtCostPerMeter"
                                                    name="rdtCostPerMeter"
                                                    value={data.foreignMachine.rdtCostPerMeter ? data.foreignMachine.rdtCostPerMeter : ""}
                                                    onChange={handleInputChange}
                                                    type="number"
                                                />
                                            </Form.Control>
                                            <Form.Help>Use a dot as decimal separator if applicable</Form.Help>
                                        </Columns.Column>
                                        <Columns.Column size={3}>
                                            <Form.Control>
                                                <LookupDropDown
                                                    dataFromParent={data.foreignMachine.rdtProductLine ? data.foreignMachine.rdtProductLine : ""}
                                                    id="rdtProductLine"
                                                    query={GET_RDTS}
                                                    name="rdtProductLine"
                                                    title="RDT Product Line"
                                                    mapQueryResult={(data: any): LookupDropDownItem => {
                                                        return data.rdtProductLines.items.map((item: any) => {
                                                            return {
                                                                id: item.id,
                                                                name: item.name,
                                                                __typename: "RDTProductLine",
                                                            };
                                                        });
                                                    }}
                                                    onChange={(obj: any) => {
                                                        const input = { ...data.foreignMachine };

                                                        input["rdtProductLine"] = obj;

                                                        // Sets state
                                                        setData({ foreignMachine: input });
                                                    }}
                                                />
                                            </Form.Control>
                                        </Columns.Column>
                                        <Columns.Column size={3}>
                                            <Form.Control>
                                                <Form.Label htmlFor="rdtBrandOfConsumable">Brand of consumables</Form.Label>
                                                <Form.Input
                                                    id="rdtBrandOfConsumable"
                                                    name="rdtBrandOfConsumable"
                                                    value={data.foreignMachine.rdtBrandOfConsumable ? data.foreignMachine.rdtBrandOfConsumable : ""}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Control>
                                        </Columns.Column>
                                    </Columns>
                                </div>
                            ) : (
                                <hr />
                            )}

                            {/* Running hours */}
                            <div className="box is-light">
                                <Columns>
                                    <Columns.Column size={6}>
                                        <Heading subtitle size={6} renderAs="h2">
                                            Diesel engine hours
                                        </Heading>
                                        <Columns className="is-vbottom">
                                            <Columns.Column size={6}>
                                                <Form.Control className="is-expanded">
                                                    <Form.Label htmlFor="dieselEngineHoursReadDate">Read date</Form.Label>
                                                    <DatePicker
                                                        className="input"
                                                        id="dieselEngineHoursReadDate"
                                                        name="dieselEngineHoursReadDate"
                                                        selected={data.foreignMachine.dieselEngineHoursReadDate ? new Date(data.foreignMachine.dieselEngineHoursReadDate) : null}
                                                        onChange={(date: any) => {
                                                            let input = { ...data.foreignMachine };
                                                            input["dieselEngineHoursReadDate"] = date.toISOString();
                                                            setData({ foreignMachine: input });
                                                        }}
                                                        dateFormat={dateFormat}
                                                        showYearDropdown
                                                    />
                                                </Form.Control>
                                            </Columns.Column>
                                            <Columns.Column size={6}>
                                                <Form.Control>
                                                    <Form.Label htmlFor="dieselEngineHours">Value</Form.Label>
                                                    <Form.Input
                                                        id="dieselEngineHours"
                                                        name="dieselEngineHours"
                                                        type="number"
                                                        value={data.foreignMachine.dieselEngineHours ?? 0}
                                                        min="0"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Control>
                                            </Columns.Column>
                                        </Columns>
                                    </Columns.Column>
                                    <Columns.Column size={6}>
                                        <Heading subtitle size={6} renderAs="h2">
                                            Impact hours
                                        </Heading>
                                        <Columns className="is-vbottom">
                                            <Columns.Column size={6}>
                                                <Form.Control>
                                                    <Form.Label htmlFor="impactHoursReadDate">Read date</Form.Label>
                                                    <DatePicker
                                                        className="input"
                                                        id="impactHoursReadDate"
                                                        name="impactHoursReadDate"
                                                        selected={data.foreignMachine.impactHoursReadDate ? new Date(data.foreignMachine.impactHoursReadDate) : null}
                                                        onChange={(date: any) => {
                                                            let input = { ...data.foreignMachine };
                                                            input["impactHoursReadDate"] = date.toISOString();
                                                            setData({ foreignMachine: input });
                                                        }}
                                                        dateFormat={dateFormat}
                                                        showYearDropdown
                                                    />
                                                </Form.Control>
                                            </Columns.Column>
                                            <Columns.Column size={6}>
                                                <Form.Control>
                                                    <Form.Label htmlFor="rh_value">Value</Form.Label>
                                                    <Form.Input
                                                        id="impactHours"
                                                        name="impactHours"
                                                        type="number"
                                                        value={data.foreignMachine.impactHours ?? 0}
                                                        min="0"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form.Control>
                                            </Columns.Column>
                                        </Columns>
                                    </Columns.Column>
                                </Columns>
                            </div>

                            {/* Sub equipment */}
                            <SubEquipment data={data} onChange={handleInputChange} />

                            {/* Form save/delete button */}
                            <Form.Field>
                                <Form.Control className="is-clearfix">
                                    {loading ? (
                                        <Button className="is-pulled-left is-loading" color="primary" disabled={true}>
                                            <span>Loading</span>
                                        </Button>
                                    ) : (
                                        <Button type="submit" className="is-pulled-left" color="primary">
                                            <Icon path={mdiContentSaveOutline} color="#fff" />
                                            <span>Save</span>
                                        </Button>
                                    )}
                                </Form.Control>

                                {!isEmpty(validationError) && (
                                    <div className="notification is-warning mt-3">
                                        <p>There's errors in the form, see above for details</p>
                                    </div>
                                )}
                            </Form.Field>
                        </form>
                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
};
