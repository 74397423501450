import React from 'react';
import { Columns, Level, Heading } from 'react-bulma-components';
import { AdminSidebar } from './shared/AdminSidebar'

export const AdminIndex = (props: any) => {
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    {/* Level */}
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">Administration</Heading>
                                </Level.Item>
                            </Level.Side>
                        </Level >
                    </div>
                    
                    {/* Page content */}
                    <div className="box">
                        <div className="content">
                            <p>Just a landing page</p>
                        </div>
                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
}
