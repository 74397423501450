import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Form } from 'react-bulma-components';
import { Error } from '../../elements/Error';
import { GET_RDTS } from '../../../database/rdt';

import AzureB2CAuthenticator from '../../../application/authentication/azure-b2c-authenticator';

var authenticator = AzureB2CAuthenticator.getInstance();

export const RdtSelect = (props: any) => {

    const [currentValue, setCurrentValue] = useState();

    // Sets page size
    const [pageSize] = useState(-1);
    const [pageIndex] = useState(-1);

    // Gets data and sets related states
    const { loading, error, data } = useQuery(GET_RDTS, {
        variables: { pageSize, pageIndex },
        fetchPolicy: 'network-only'
    });

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {

        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // Sets state
        setCurrentValue(value);

        // Send input up state
        props.onChange(e);
    }

    const identity = authenticator.getIdentity();

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <Form.Control>
            <Form.Select name="rdtProductLine" value={currentValue} onChange={handleInputChange} className="is-fullwidth">
                <option value="">RDT Product Line</option>
                { 
                    !loading && data.rdtProductLines.items.length !== 0 ? (
                        data.rdtProductLines.items.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item.name}>{item.name}</option>
                            );
                        })
                    ) : ''
                }
            </Form.Select>
        </Form.Control>
    );
}

export interface RdtSelectProps {

}
