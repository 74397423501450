import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Columns, Form, Level, Button, Heading, Notification } from 'react-bulma-components';
import { AdminSidebar } from './../shared/AdminSidebar';
import { Icon } from '../../../elements/Icon';
import { mdiArrowLeft, mdiContentSaveOutline } from '@mdi/js';
import { CREATE_MACHINE_TYPE } from '../../../../database/machinetypes';

export const AdminMachineTypeCreate = (props: any) => {

    // History is used to redirect users after save/edit/delete
    const history = useHistory();

    // Redirect path
    const redirectTo = '/admin/machinetypes';

    // Adds data to state
    const [data, setData] = useState({} as any);

    // Define create mutation
    const [createMachineType, { loading, error }] = useMutation(CREATE_MACHINE_TYPE, {
        onCompleted: (data) => handleUpdateSuccess()
    });

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {

        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // Get the current data set
        const input: any = { ...data };

        // Sets data key with a es6 computed property name
        // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Operators/Object_initializer#Computed_property_names
        input[name] = value;

        // Sets state
        setData(input);

    }

    const handleValidation = (brand: any) => {
        return;
    }

    /*
    * Takes care of the submitted data and saves/updates
    */
    const handleSubmit = (event: any) => {

        event.preventDefault();

        // Define data set
        const machineType = {
            name: data.name,
            rdtEnabled: data.rDTEnabled ?? false
        }

        // @todo validation
        handleValidation(machineType);

        // Saves data and calls handleUpdateSuccess()
        createMachineType({
            variables: { machineType: machineType }
        });

    }

    /*
    * Takes care of the what happens after data has been updated
    */
    const handleUpdateSuccess = () => {

        // Clear existing data
        setData({});

        // Redirect to list with state
        history.push({
            pathname: redirectTo,
            state: { created: true }
        });

    }

    // Renders the page
    return (
        <div>
            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>
                    {/* Level */}
                    <div className="box is-yellow is-marginless">
                        <Level renderAs="nav">
                            <Level.Side align="left">
                                <Level.Item>
                                    <Button onClick={history.goBack} color="primary" size="small">
                                        <Icon path={mdiArrowLeft} color="#fff" />
                                        <strong>Back</strong>
                                    </Button>
                                </Level.Item>
                                <Level.Item>
                                    <Heading subtitle size={3} renderAs="h2">Create a machine type</Heading>
                                </Level.Item>
                            </Level.Side>
                        </Level >
                    </div>

                    {/* Page content */}
                    <div className="box">

                        {error && (
                            <Notification color="danger">
                                <strong>Error:</strong> Please try again
                            </Notification>
                        )}

                        {/* Form */}
                        <form onSubmit={handleSubmit}>

                            <Columns>
                                <Columns.Column size={12}>
                                    <Form.Control>
                                        <Form.Label htmlFor="name">Name</Form.Label>
                                        <Form.Input id="name" name="name" value={data.name} onChange={handleInputChange} autoComplete="off" />
                                    </Form.Control>
                                </Columns.Column>
                                <Columns.Column size={12}>
                                    <Form.Control>
                                        <input className="is-checkradio" id="rDTEnabled" type="checkbox" name="rDTEnabled" checked={data.rDTEnabled ?? false} onChange={handleInputChange} />
                                        <label htmlFor="rDTEnabled">RDT enabled</label>
                                    </Form.Control>
                                </Columns.Column>
                            </Columns>

                            {/* Form save button */}
                            <Form.Field>
                                <Form.Control className="is-clearfix">
                                    {loading ? (
                                        <Button className="is-pulled-left is-loading" color="primary" size="small" disabled={true}>
                                            <span>Loading</span>
                                        </Button>
                                    ):(
                                        <Button type="submit" className="is-pulled-left" color="primary" size="small">
                                            <Icon path={mdiContentSaveOutline} color="#fff" />
                                            <span>Save</span>
                                        </Button>
                                    )}

                                </Form.Control>

                            </Form.Field>

                        </form>

                    </div>
                </Columns.Column>
            </Columns>
        </div>
    );
}
