import React from 'react';
import {Icon as Mdi} from '@mdi/react';

export const Icon = (props: any) => {
    return (
        <span className={ `icon ${ props.size }` }>
            <Mdi path={props.path} color={props.color} size={props.size} spin={props.spin} />
        </span>
    );
}

