import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Form } from 'react-bulma-components';
import { Icon } from '../../elements/Icon';
import { Error } from '../../elements/Error';
import { GET_COUNTRIES } from '../../../database/countries';
import { mdiEarth } from '@mdi/js';

import AzureB2CAuthenticator from '../../../application/authentication/azure-b2c-authenticator';

var authenticator = AzureB2CAuthenticator.getInstance();

export const CountriesSelect = (props: any) => {

    const [currentValue, setCurrentValue] = useState();

    // Sets page size
    const [pageSize] = useState(-1);
    const [pageIndex] = useState(-1);

    // Gets data and sets related states
    const { loading, error, data } = useQuery(GET_COUNTRIES, {
        variables: { pageSize, pageIndex },
    });

    /*
    * Sets input state changes dynamicly through name attribute
    */
    const handleInputChange = (e: any) => {

        // Define variables and default behaviours
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        // Sets state
        setCurrentValue(value);

        // Send input up state
        props.onChange(e);
    }

    const identity = authenticator.getIdentity();

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <Form.Control className="has-icons-left">
            <Form.Select name="country" value={currentValue}  className="is-fullwidth" onChange={handleInputChange}>
                <option value="">Countries</option>
                { 
                        !loading && data.countries.items.length !== 0 ? (
                            data.countries.items.filter((item: any) => { return identity?.famCodeAccess.includes(item.famCode); }).map((item: any, index: number) => {
                                return (
                                    <option key={index} value={item.name}>{item.name}</option>
                            );
                        })
                    ) : ''
                }
            </Form.Select>
            <Icon className="is-left" path={mdiEarth} size="is-small" color="#eaeaea" />
        </Form.Control>
    );
}

export interface CountriesSelectProps {

}
