import gql from 'graphql-tag';

export const GET_BRANDS = gql`
    query GetBrands($name: String, $pageSize: Int!, $pageIndex: Int!) {
        brands(name: $name, pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
             }
            total
        }
    }
`;

export const GET_BRAND = gql`
    query GetBrand ($id: ID!) {
        brand (id: $id) {
            id
            name
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_BRAND = gql`
    mutation CreateBrand ($brand: brandCreateInput!) {
        createBrand (brand: $brand) {
            id
            name
            updated
            created
        }
    }
`;

export const UPDATE_BRAND = gql`
    mutation UpdateBrand ($brand: brandUpdateInput!, $updated: DateTime!) {
        updateBrand (brand: $brand, updated: $updated) {
            id
            name
            updated
            created
        }
    }
`;

export const DELETE_BRAND = gql`
    mutation DeleteBrand ($id: ID!){
        deleteBrand (id: $id)
    }
`;