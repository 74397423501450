import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Columns, Level, Pagination, Heading, Table, Notification } from 'react-bulma-components';
import queryString from 'query-string';
import { DateTime } from "luxon";
import { dateFormat } from '../../../../config/app';
import { Error } from '../../../elements/Error';
import { AdminSidebar } from './../shared/AdminSidebar';
import { Icon } from '../../../elements/Icon';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { GET_ROCK_CONDITIONS } from '../../../../database/rockconditions';
import { saveAs } from "file-saver";

export const AdminRockConditionIndex = (props: any) => {

    // Uses location and history for redirecting with correct data
    const history = useHistory();
    const location = useLocation();

    // Get location state (state is passed though history.push)
    const state = location.state as any;

    // Sets page size
    const [pageSize] = useState(15);
    const [pageIndex, setPageIndex] = useState(location.search ? Number(queryString.parse(location.search).page) : 1);

    // Gets data and sets related states
    const { loading: queryLoading, error, data } = useQuery(GET_ROCK_CONDITIONS, {
        variables: { pageSize, pageIndex },
        fetchPolicy: 'network-only'
    });

    // Handles pagination clicks 
    const onPaginationPageChange = (page: number) => {

        // Sets the new page index, used by pagination
        setPageIndex(page);

        // Send query params
        history.push('?page=' + page);

    };

    console.log(data);

    // Checks for error in the fetched result
    if (error) return <Error message={error.message} />;

    // Renders the page
    return (
        <div>

            <Columns>
                <Columns.Column className="is-2">
                    <AdminSidebar />
                </Columns.Column>
                <Columns.Column>


                        <div className="box is-yellow is-marginless is-mobile">
                            <Level renderAs="nav">
                                <Level.Side align="left">
                                    <Level.Item>
                                        <Heading subtitle size={3} renderAs="h2">Rock conditions</Heading>
                                    </Level.Item>
                                </Level.Side>

                            {queryLoading ?

                                <Level.Side align="right">
                                    <Level.Item>
                                        <div className="button is-text is-loading has-icon-dark" title="Loading"></div>
                                    </Level.Item>
                                </Level.Side>

                            :''}

                            </Level>
                        </div>

                    <div>

                        {!queryLoading ? (

                            <div className="box">
                        
                                {/* Level */}
                                <Level renderAs="nav">
                                    <Level.Side align="left">
                                        <Level.Item>
                                            <Heading size={5} subtitle>
                                                Found <strong>{data.rockConditions.total}</strong> rock conditions
                                            </Heading>
                                        </Level.Item>
                                    </Level.Side>

                                    <Level.Side align="right">
                                        <Level.Item>
                                            <Link to="/admin/rockconditions/create" className="button is-small is-light" >
                                                <Icon path={mdiPlus} color="#363636" />
                                                <strong>Create a rock condition</strong>
                                            </Link>
                                        </Level.Item>
                                    </Level.Side>
                                </Level>

                                {state && state.created && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been created
                                    </Notification>
                                )}

                                {(state && state.updated) && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been updated
                                    </Notification>
                                )}

                                {state && state.deleted && (
                                    <Notification color="success">
                                        <strong>Success:</strong> Item has been deleted
                                    </Notification>
                                )}

                                <div className="table-container">
                                    <Table className="is-hoverable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th className="is-narrow">Created at</th>
                                                <th className="is-narrow">Updated at</th>
                                                <th className="is-narrow has-text-right"></th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Name</th>
                                                <th className="is-narrow">Created at</th>
                                                <th className="is-narrow">Updated at</th>
                                                <th className="is-narrow has-text-right"></th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {
                                                // Checks is the filtered (if any) result isn't empty
                                                data.rockConditions.items.length !== 0 ? (

                                                    // Displays each row of the result
                                                    data.rockConditions.items.map((item:any, index:number) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td>{item.name}</td>
                                                                <td className="is-narrow">{DateTime.fromISO(item.created).toFormat(dateFormat)}</td>
                                                                <td className="is-narrow">{DateTime.fromISO(item.updated).toFormat(dateFormat)}</td>
                                                                <td className="is-narrow">
                                                                    <Link to={{ pathname: `/admin/rockconditions/rockcondition/${item.id}`, search: location.search }} className="button is-text is-small">
                                                                        <Icon path={mdiPencil} color="#363636" />
                                                                        <strong>Edit</strong>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })

                                                ) : (
                                                    // Returns a message if the result was empty
                                                    <tr><td colSpan={4}>Nothing found..</td></tr>
                                                )}
                                        </tbody>
                                    </Table>

                                </div>

                                {/* Displays pagination buttons */}
                                <Pagination className="is-dark is-small" current={pageIndex} total={Math.ceil(data.rockConditions.total / pageSize)} delta={2} onChange={(page: number) => onPaginationPageChange(page)} />

                            </div>
                
                        ) : ''}

                    </div>

                </Columns.Column>
            </Columns>
        </div>
    );
}
