import gql from "graphql-tag";

export const GET_MACHINE_TYPES = gql`
    query GetMachineTypes($name: String, $pageSize: Int!, $pageIndex: Int!) {
        machineTypes(name: $name, pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                rdtEnabled
                updated
                created
            }
            total
        }
    }
`;

export const GET_MACHINE_TYPE = gql`
    query GetMachineType($id: ID!) {
        machineType(id: $id) {
            id
            name
            rdtEnabled
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_MACHINE_TYPE = gql`
    mutation CreateMachineType($machineType: machineTypeCreateInput!) {
        createMachineType(machineType: $machineType) {
            id
            name
            rdtEnabled
            updated
            created
        }
    }
`;

export const UPDATE_MACHINE_TYPE = gql`
    mutation UpdateMachineType($machineType: machineTypeUpdateInput!, $updated: DateTime!) {
        updateMachineType(machineType: $machineType, updated: $updated) {
            id
            name
            rdtEnabled
            updated
            created
        }
    }
`;

export const DELETE_MACHINE_TYPE = gql`
    mutation DeleteMachineType($id: ID!) {
        deleteMachineType(id: $id)
    }
`;
