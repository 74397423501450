import gql from 'graphql-tag';

export const GET_RDTS = gql`
    query rdtProductLines ($pageSize: Int!, $pageIndex: Int!) {
        rdtProductLines (pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
            }
            total
        }
    }
`;

export const GET_RDT = gql`
    query rdtProductLine ($id: ID!) {
        rdtProductLine (id: $id) {
            id
            name
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_RDT = gql`
    mutation createRDTProductLine ($rdtProductLine: rdtProductLineCreateInput!) {
        createRDTProductLine (rdtProductLine: $rdtProductLine) {
            id
            name
            updated
            created
        }
    }
`;

export const UPDATE_RDT = gql`
    mutation updateRDTProductLine ($rdtProductLine: rdtProductLineUpdateInput!, $updated: DateTime!) {
        updateRDTProductLine (rdtProductLine: $rdtProductLine, updated: $updated) {
            id
            name
            updated
            created
        }
    }
`;

export const DELETE_RDT = gql`
    mutation deleteRDTProductLine ($id: ID!){
        deleteRDTProductLine (id: $id)
    }
`;