import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'react-bulma-components';

export const AdminSidebar = (props: any) => {
    return (
        <Menu>
            <Menu.List title="General">
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin" activeClassName="is-active" exact>
                        Administration
                    </NavLink>
                </Menu.List.Item>
            </Menu.List>
            <Menu.List title="Edit">
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/brands" activeClassName="is-active">
                        Brands
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/countries" activeClassName="is-active">
                        Countries
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/segments" activeClassName="is-active">
                        Segments
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/commodities" activeClassName="is-active">
                        Commodities
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/rdts" activeClassName="is-active">
                        RDT Product Lines
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/rockconditions" activeClassName="is-active">
                        Rock Conditions
                    </NavLink>
                </Menu.List.Item>
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/machinetypes" activeClassName="is-active">
                        Machine Types
                    </NavLink>
                </Menu.List.Item>            
            </Menu.List>

            <Menu.List title="Manage">
                <Menu.List.Item renderAs="div">
                    <NavLink to="/admin/import" activeClassName="is-active">
                        Import
                    </NavLink>
                </Menu.List.Item>
            </Menu.List>
        </Menu>
    );
}
