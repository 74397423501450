import React from 'react';
import { Notification } from 'react-bulma-components';

export const Error = (props: any) => {
    return (
        <Notification color="danger">
            <strong>Error:</strong> {props.message}
        </Notification>
    );
}
