import React, { useState } from "react";

import { useQuery } from "@apollo/client";
import Autocomplete from "react-autocomplete-2";
import { DocumentNode } from "graphql";

export interface AutocompleteProps {
    query: DocumentNode;
    onItemSelected: (item: any) => void;
    mapQueryResult: (data: any) => AutocompleteItem;
    id: string;
    value: string;
    name: string;
    onChange?: any;
    className?: any;
    pick?: number;
    placeholderText?: string;
    distinct?: boolean;
}

export interface AutocompleteItem {
    id: string;
    name: string;
}

export const AutocompleteInput = (props: AutocompleteProps) => {
    const { mapQueryResult, onItemSelected, query, id, pick, distinct, placeholderText, ...childProps } = props;
    // Sets page size
    const [pageSize] = useState(props.pick ?? 50);

    const { loading, error, data } = useQuery(query, {
        variables: { pageSize, pageIndex: 1, filter: {distinctOnModel: distinct ? true : false }},
        fetchPolicy: "network-only",
    });

    const [value, setValue] = useState(props.value);

    const wrapperProps = {
        className: "dropdown is-active",
    };

    const wrapperStyles = {
        width: "100%",
    };

    const menuStyles = {};
    const onMenuVisibilityChange = (isOpen: Boolean) => {};

    const handleItemChange = (item: AutocompleteItem) => {
        setValue(item.name);
        onItemSelected(item);
    };

    const shouldItemRender = (item: any, value: any) => {
        return item.name !== undefined && item != null && item.name != null && value != null && item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
    };

    if (loading)
        return (
            <div className="control is-loading">
                <input className="input" {...childProps} value="Loading..." />
            </div>
        );
    if (error) return <>{`Error! ${error.message}`}</>;

    return (
        <Autocomplete
            selectOnBlur={true}
            autoHighlight={true}
            items={mapQueryResult(data)}
            suggestionsMenuId={id}
            getItemValue={(item: any) => item.name}
            shouldItemRender={shouldItemRender}
            renderInput={(props: any) => <input id={id} className="input" {...props} autoComplete={"mol_" + id} />}
            inputProps={childProps}
            renderMenu={(items: any, value: any, style: any, menuStyle: any, suggestionsMenuId: any) => {
                // Very hacky, but removes visible dropdown oterhwise shown when items are empty.. Funky component
                return items.length !== 0 ? (
                    <div id={suggestionsMenuId} className="dropdown-menu" style={{ ...menuStyle }}>
                        <div className="dropdown-content" children={items} />
                    </div>
                ) : (
                    <div id={suggestionsMenuId} className="dropdown-menu" style={{ ...menuStyle }} children={items} />
                );
            }}
            renderItem={(item: any, isHighlighted: any) => (
                <div key={item.id} aria-selected={isHighlighted} className="dropdown-item" style={{ background: isHighlighted ? "lightgray" : "white" }}>
                    {item.name}
                </div>
            )}
            menuStyle={menuStyles}
            wrapperProps={wrapperProps}
            wrapperStyle={wrapperStyles}
            value={value}
            onChange={(e: any) => {
                props.onChange(e);
                setValue(e.target.value);
            }}
            onSelect={(value: any, item: AutocompleteItem) => handleItemChange(item)}
            onMenuVisibilityChange={onMenuVisibilityChange}
        />
    );
};
