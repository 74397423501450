import React, { Component } from 'react';

import {
  Redirect,
  Route
} from 'react-router-dom';

import { Identity } from '../application/authentication/azure-b2c-authenticator';

export default class AdminRoute extends Component<AdminRouteProps, AdminRouteState> {
  constructor(props: AdminRouteProps) {
    super(props);
    this.state = {
      isAdmin: false,
    }
  }

  async componentDidMount(): Promise<void> {
    this.setState({ isAdmin: this.props.identity?.isAdmin ?? false });
  }
  render() {
    const { identity: Identity, ...props } = this.props;
    return (
      this.props.identity?.isAdmin ? <Route 
        {...props } 
      /> : <Redirect to='/' />  
    );
  }
}

export interface AdminRouteProps {
  identity: Identity | null;
  path: string;
  component: any;
  exact?: any;
}

export interface AdminRouteState {
  isAdmin: boolean;
}