import gql from "graphql-tag";

export const GET_COUNTRIES = gql`
    query GetCountries($name: String, $pageSize: Int!, $pageIndex: Int!) {
        countries(name: $name, pageSize: $pageSize, pageIndex: $pageIndex) {
            items {
                id
                name
                updated
                created
                famCode
            }
            total
        }
    }
`;

export const GET_COUNTRY = gql`
    query GetCountry($id: ID!) {
        country(id: $id) {
            id
            name
            famCode
            updated
            created
            updatedBy
            createdBy
        }
    }
`;

export const CREATE_COUNTRY = gql`
    mutation CreateCountry($country: countryCreateInput!) {
        createCountry(country: $country) {
            id
            name
            famCode
            updated
            created
        }
    }
`;

export const UPDATE_COUNTRY = gql`
    mutation UpdateCountry($country: countryUpdateInput!, $updated: DateTime!) {
        updateCountry(country: $country, updated: $updated) {
            id
            name
            famCode
            updated
            created
        }
    }
`;

export const DELETE_COUNTRY = gql`
    mutation DeleteCountry($id: ID!) {
        deleteCountry(id: $id)
    }
`;
